import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import EventSlider from './../../Element/EventSlider';

import bgimg from './../../../images/bg-view.png';

class EventSection extends Component {
	render() {
		return (
			<Fragment>
				<section className="content-inner-2">
					<div className="container">
						<div className="row align-items-end section-head">
							<div className="col-md-6">
								<h2 className="title">OTROS PROYECTOS</h2>
								<div className="dlab-separator bg-primary"></div>
								<p className="mb-3 mb-md-0">Tenemos diferentes sectores en desarrollo que te van a encantar…</p>
							</div>
							<div className="col-md-6 text-md-right">
								<Link to={"/proyectos"} className="btn btn-primary">Proyectos</Link>
							</div>
						</div>
						{/* Blog Carousel Start*/}
						<EventSlider />
						{/*Blog Carousel End*/}
					</div>
					{/* 
				<img src={bgimg} className="bg-view"  alt=""/>	
				Section-7 End*/}
				</section>
			</Fragment>
		)
	}
}
export default EventSection;