import img1 from '../../images/trayectoria/PORTO_LETICIA/Sector_Porto_Leticia_Apartamentos_Bello_Antioquia_1.png'
import img2 from '../../images/trayectoria/PORTO_LETICIA/Sector_Porto_Leticia_Apartamentos_Bello_Antioquia_2.png'
import img3 from '../../images/trayectoria/PORTO_LETICIA/Sector_Porto_Leticia_Apartamentos_Bello_Antioquia_3.png'


export default function PortoLeticia() {
    const data =   
    [
        {
            "name": "Porto Leticia",
            "slug": "porto-leticia",
            "location": "Bello, Antioquia.",
            "text": "Construimos en el SECTOR de Bello, Antioquia; una Unidad Residencial de Apartamentos en dos torres con áreas de 63 y 64 m2; zonas comunes como dos porterías, piscinas, cancha de golfito, parque infantil, BBQ y salón social.",
            "images":  [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Porto Leticia.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Bello, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
