import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

//Light Gallery on icon click 
const Iconimage = props => {
    const { openLightbox } = useLightbox()

    return (
        <Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="lightimg " >
            <i className="ti-zoom-in icon-bx-xs"></i>
        </Link>
    )
}


const BlogPage = ({ imageType }) => {
    return (
        <>
            <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                <img src={imageType} alt="" />
                <div className="overlay-bx">
                    <Iconimage />
                </div>
            </div>
        </>
    )
}


class Data extends Component {
    render() {
        return (
            <Fragment>
            </Fragment>
        )
    }
}

const detailBlog = [
    { icon: <i className="ti ti-user" />, title: 'CLIENT', subtitle: 'Martin Stewart' },
    { icon: <i className="ti ti-user" />, title: 'ARCHITECT', subtitle: 'Jimmy Smith' },
    { icon: <i className="ti ti-location-pin" />, title: 'LOCATION', subtitle: 'London, UK' },
    { icon: <i className="ti ti-ruler-alt-2" />, title: 'SIZE', subtitle: '1,200m' },
    { icon: <i className="ti ti-home" />, title: 'TYPE', subtitle: 'Residential Project' },
];

function ProDetail() {
    return (
        <>
            {detailBlog.map((data, index) => (
                <div className="col p-lr0" key={index}>
                    <div className="pro-details">{data.icon}<strong>{data.title}</strong> {data.subtitle}</div>
                </div>
            ))}
        </>
    )
}

const ButtonPara = ({ video, whatapp }) => {
    return (
        <>
            <div className="text-center">
                <a href={video} className="popup-youtube m-r20 video btn btn-primary btn-video">
                    <i className="fa fa-play"></i><span></span>
                </a>
                <Link to={{ pathname: whatapp }} className="btn btn-primary" target="_blank">¡QUIERO SABER MÁS!<span></span></Link>
            </div>
        </>
    )
}

export { ProDetail, ButtonPara, BlogPage };
export default Data; 