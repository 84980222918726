import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import poblado_del_hato from './../../../images/home/box3/poblado_del_hato.jpg';
import citadela_di_aqua from './../../../images/home/box3/citadela_di_aqua.jpg';
import terragrande from './../../../images/home/box3/terragrande.jpg';
import antioquia_premium from './../../../images/home/box3/antioquia_premium.jpg';
import ciudadela_del_sol from './../../../images/home/box3/ciudadela_del_sol.jpg';
import mirador_del_cacique from './../../../images/home/box3/mirador_del_cacique.jpg';
import altos_de_monteverde from './../../../images/home/box3/altos_de_monteverde.jpg';
import bella_terra from './../../../images/home/box3/bella_terra.jpg';
import citadela_di_terra from './../../../images/home/box3/citadela_di_terra.jpg';
import essenza from './../../../images/home/box3/essenza.jpg';
import main1 from './../../../images/gallery/main1.jpg';
import dataTrayectoria from '../../../infrastructure/data/trayectoria.json'


const data = dataTrayectoria.filter(datas =>
	datas.name === "Poblado del Hato" ||
	datas.name === "Citadela Di Aqua" ||
	datas.name === "Terragrande" ||
	datas.name === "Antioquia Premium" ||
	datas.name === "Ciudadela del Sol" ||
	datas.name === "Mirador del Cacique" ||
	datas.name === "Altos de Monteverde" ||
	datas.name === "Bella Terra" ||
	datas.name === "Citadela Di Terra" ||
	datas.name === "Essenza"
)

const imagenes = [
	{ label: "Poblado del Hato", img: poblado_del_hato },
	{ label: "Citadela Di Aqua", img: citadela_di_aqua },
	{ label: "Terragrande", img: terragrande },
	{ label: "Antioquia Premium", img: antioquia_premium },
	{ label: "Ciudadela del Sol", img: ciudadela_del_sol },
	{ label: "Mirador del Cacique", img: mirador_del_cacique },
	{ label: "Altos de Monteverde", img: altos_de_monteverde },
	{ label: "Bella Terra", img: bella_terra },
	{ label: "Citadela Di Terra", img: citadela_di_terra },
	{ label: "Essenza", img: essenza }
]

/*
linea 61
						<div className="col-md-6 text-md-right">
							<Link to={"./trayectoria"} className="btn btn-primary">VER TRAYECTORIA</Link>
						</div>
*/
class NuestraTrayectoria extends Component {
	render() {
		return (
			<section className="content-inner-2" data-content="Nuestra Trayectoria" id="sidenav_ourServices">
				<div className="container">
					<div className="row align-items-end section-head" >
						<div className="col-md-6">
							<h2 className="title">Nuestra Trayectoria</h2>
							<div className="dlab-separator bg-primary"></div>
							<p className="mb-3 mb-md-0">
								Nos caracterizan más de 28 años construyendo en diferentes sectores de Colombia.
							</p>
						</div>



					</div>
				</div>
				<div className="service-area">
					<div className="row spno service-row">
						{data.map((data) => (
							<div className="col ">
								<div className="service-box">
									<div className="media">
										<img src={imagenes.filter(item => item.label === data.name).map(item => item.img)} alt={data.name} />
									</div>
									<div className="info">
										<Link to={"./" + data.slug}><h4 className="title">{data.name}</h4></Link>
										<p>{data.location}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		);
	}
}

export default NuestraTrayectoria;
