import React from 'react'
import { Link } from 'react-router-dom';
import bgimg from './../../images/bg-view.png';

export default function MapBox() {
    return (
        <div className="container">
            <section className="content-inner-2">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="title">¡CONÓCENOS MÁS!</h2>
                    </div>
                    <div className="banner-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.7133430741906!2d-75.8321656!3d6.5578248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e45b7e917c37e45%3A0x8aab2c972ae0fa52!2sCitadela%20Di%20Sole!5e0!3m2!1sen!2sca!4v1719185479384!5m2!1sen!2sca"
                            className="border-0 align-self-stretch rounded-sm" style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen
                        />
                    </div>
                </div>
            </section>
            <section className="content-inner-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="icon-bx-wraper m-sm-b30 left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-phone-volume"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">TELÉFONOS</h3>
                                    <p>Sala de Negocios: (+57) 3245730303<br />
                                        Asesora Marinela: (57) 3125767304<br />
                                        Asesora Catalina: (+57) 3113403242<br />

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="icon-bx-wraper m-sm-b30 left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-map"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">DIRECCIÓN</h3>
                                    <p>Carrera 14 # 6- 239<br />
                                        Santa Fe de Antioquia<br />
                                        Antioquia, Colombia<br />

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="icon-bx-wraper left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-envelope-open"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">CORREO</h3>
                                    <p>
                                        Sala de Negocios: disole@sector.com.co<br />
                                        Trámites: tramites@sector.com.co<br />
                                        Coordinación Comercial: coor.comercial@sector.com.co<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
