
import img1 from '../../images/proyectos/CAMPESTRE_REAL/BOX 2 zonas comunes_campestre_real.jpg'
import img2 from '../../images/proyectos/CAMPESTRE_REAL/BOX 3 ceja_real.png'
import img3 from '../../images/proyectos/CAMPESTRE_REAL/BOX 4 características_ceja_real.png'
import img6 from '../../images/proyectos/CAMPESTRE_REAL/BOX 5 portería_campestre_real.jpg'
import img4 from '../../images/proyectos/CAMPESTRE_REAL/BOX 8 campestre_real_fachada.jpg'
import img5 from '../../images/proyectos/CAMPESTRE_REAL/BOX 9 interior_casa_campestre_real.jpg'

export default function CampestreReal() {
    const data =
        [
            {
                "name": "Campestre Real",
                "slug": "campestre-real",
                "subtitle": "LOTES DESDE 104 m2 hasta 191 m2.",
                "zonas": "El proyecto te brindará la oportunidad de disfrutar con tu familia o amigos de amplias zonas comunes como:",
                "location": "La Ceja, Antioquia, Colombia.",
                "text": "CAMPESTRE REAL está compuesto por 217 lotes desde 104 m2 hasta 191 m2 en el cual podrás construir casas de 140 m2 aproximadamente bajo un diseño eco-amigable enfocado en el uso de luz natural, contemplación de doble altura en espacio social y un generoso frente de 8 metros con dos parqueaderos individuales y parqueaderos de bicicletas u opción de modificación a un parqueadero y una habitación en primera planta. También contarás con un gran jardín interior de 20 m2 aproximadamente, en el cual tendrás un espacio donde desarrollar tu propia eco-huerta, zona húmeda, BBQ u otras comodidades si así lo quisieras.",
                "text1": "El macroproyecto CEJA REAL, Natural y Bike Friendly con más de 3 km de ciclorruta integrada, un entorno planeado con Centro de Atención Médico, Mall Comercial, Casa del Adulto Mayor, Estación de Servicio y viviendas, se encuentra en construcción de su primer proyecto llamado CAMPESTRE REAL compuesto por 217 lotes desde 104 m2 hasta 191 m2 en el cual podrás construir casas de 140 m2 aproximadamente con 2 parqueaderos individuales y un jardín interno de 20 m2 aprox.",
                "text2": "Cuenta con una privilegiada ubicación en medio de la Nueva Circunvalar Sur doble Calzada con 4 vías de acceso: Vía Medellín, Vía Llanogrande, Vía Unión  y Vía Abejorral. Adicionalmente, tendremos un acceso por la Casa de Retiros Espirituales “La Colombiere”. Encuéntranos en Waze o en Google Maps como Ceja Real y atrévete a conocer este paraíso del oriente antioqueño.",
                "video": "https://www.youtube.com/channel/UChnRgtI40lFiKVbQa4XN3jA",
                "whatapp": "https://api.whatsapp.com/send?phone=573115101010&text=Hola,%20me%20gustaria%20recibir%20mas%20informaci%C3%B3n%20y%20comunicarme%20con%20uno%20de%20sus%20asesores",
                "maps": "https://www.google.com/maps/dir/6.2012888,-75.5789893/ceja+real/@6.1214679,-75.5717503,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8e46975278687855:0x3f2cf8083a36eaab!2m2!1d-75.4269563!2d6.017723",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                ],
                "iconos": [
                    { icono: <i className="ti ti-bookmark" />, texto: "Portería con vigilancia 24h " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Circuito Cerrado de Televisión" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Gimnasio" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Salón Social" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha en Grama Sintética" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha Polideportiva" },
                    { icono: <i className="ti ti-bookmark" />, texto: "2 Parques de Mascotas" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Juegos Infantiles" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Locales comerciales" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Árboles Nativos" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parqueaderos de Visitantes" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parqueaderos de Bicicletas." },
                ]
            }
        ]
    return data;
}
