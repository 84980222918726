import img1 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-1.png';
import img2 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-2.png';
import img3 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-3.png';
import img4 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-4.png';
import img5 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-5.png';
import img6 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-6.png';
import img7 from '../../images/trayectoria/ANTIOQUIA_PREMIUM/Antioquia-premium-7.png';


export default function AntioquiaPremium() {
    const data =
        [
            {
                "name": "Antioquia Premium",
                "slug": "antioquia-premium",
                "location": "Santa Fe de Antioquia, Antioquia.",
                "text": "Construimos en el SECTOR de Santa Fe de Antioquia, Antioquia, un Conjunto Residencial de 113 casas de 82 y 102 m2 construidas con dos y tres niveles en lotes individuales de 104 y 212 m2; con amplias zonas comunes incluyendo 6 piscinas, jacuzzi, un kiosko, playas en arena de cuarzo, turco, solárium, cancha de microfútbol en grama sintética, cancha de vóley playa y juegos infantiles.",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                ],
                "iconos": [
                    { icon: <i className="ti ti-user" />, title: 'PROYECTO', subtitle: 'Antioquia Premium.' },
                    { icon: <i className="ti ti-location-pin" />, title: 'SECTOR', subtitle: 'Santa Fe de Antioquia, Antioquia.' },
                    { icon: <i className="ti ti-home" />, title: 'TIPO', subtitle: 'Conjunto Residencial' },
                    { icon: <i className="ti ti-ruler-alt-2" />, title: 'CONSTRUYE', subtitle: 'Ingearco y cia s.a.s' },
                ]
            }
        ]
    return data;
}
