import React, { Component } from 'react'
import Slider from "react-slick";
import logo1 from './../../../images/logo/ALIANZA-FIDUCIARIA.png';
import logo2 from './../../../images/logo/INGEARCO.png';
import logo3 from './../../../images/logo/SECTOR-CONSTRUCTOR.png';
import logo4 from './../../../images/logo/SECTOR-INMOBILIARIO.png';
import logo5 from './../../../images/logo/SECTOR-COMERCIAL.png';

const aboutBlog = [
    {  image: logo2,  }, /*Ingearco*/
	{  image: logo3,  }, /*Sector Constructor*/
    {  image: logo4,  }, /*Sector Inmobiliario*/
    {  image: logo5,  }, /*Sector Comercial*/
    //{  image: logo1,  }, /*Alianza*/
]

/* function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right "  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
   <div className=" owl-prev la la-angle-left " onClick={onClick}/>
	</div>
  );
}
 */
class Detail3Owl extends Component {

    render() {
        var settings = {
            dots: false,
			arrows: false,
            slidesToShow: 4,
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 5,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 5,
				  }
				},
				{
				  breakpoint: 768,
				  settings: {
					slidesToShow: 4,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 2,
				  }
				}
			]
        };
        return (
            <>		
				<Slider className="client-logo owl-carousel owl-none" {...settings}>
					{aboutBlog.map((item, index) => (
						<div className="item">
							<div className="client-logo-inner">
								<img src={item.image} alt="" />
							</div>
						</div>	
					))}
				</Slider>	
			</>
        )
    }
}
export default Detail3Owl;