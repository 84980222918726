import pobladoDelHato from "../entities/pobladoDelHato"
import citadelaDiAqua from "../entities/citadelaDiAqua";
import Terragrande from "../entities/Terragrande";
import AntioquiaPremium from "../entities/AntioquiaPremium";
import CitadelaDiSole from "../entities/CitadelaDiSole";
import CiudadeladelSol from "../entities/CiudadeladelSol";
import MiradordelCacique from "../entities/MiradordelCacique";
import AltosdeMonteverde from "../entities/AltosdeMonteverde";
import BellaTerra from "../entities/BellaTerra";
import CitadelaDiTerra from "../entities/CitadelaDiTerra";
import Essenza from "../entities/Essenza";
import CiudadelaPremium from "../entities/CiudadelaPremium";
import CiudadelaSantaFe from "../entities/CiudadelaSantaFe";
import Valparaiso from "../entities/Valparaiso";
import PortoLeticia from "../entities/PortoLeticia";
import AltosdeValparaiso from "../entities/AltosdeValparaiso";
import JardinesdelaPlaza from "../entities/JardinesdelaPlaza";
import SanLorenzodelEscorial from "../entities/SanLorenzodelEscorial";
import CaminosdeSanPatricio from "../entities/CaminosdeSanPatricio";

export default function TrayectoriaService(param) {
    
    switch(param) {
        case 'poblado-del-hato':
            return pobladoDelHato();
        case 'citadela-di-aqua':
            return citadelaDiAqua();
        case 'terragrande':
            return Terragrande();
        case 'antioquia-premium':
            return AntioquiaPremium();
        case 'citadela-di-sole':
            return CitadelaDiSole();
        case 'ciudadela-del-sol':
            return CiudadeladelSol();
        case 'mirador-del-cacique':
            return MiradordelCacique();
        case 'altos-de-monteverde':
            return AltosdeMonteverde();
        case 'bella-terra':
            return BellaTerra();
        case 'citadela-di-terra':
            return CitadelaDiTerra();
        case 'essenza':
            return Essenza();
        case 'ciudadela-premium':
            return CiudadelaPremium();
        case 'Ciudadela-santa-fe':
            return CiudadelaSantaFe();
        case 'valparaiso':
            return Valparaiso();
        case 'porto-leticia':
            return PortoLeticia();
        case 'altos-de-valparaiso':
            return AltosdeValparaiso();
        case 'jardines-de-la-plaza':
            return JardinesdelaPlaza();
        case 'san-lorenzo-del-escorial':
            return SanLorenzodelEscorial();
        case 'caminos-de-san-patricio':
            return CaminosdeSanPatricio();
        default:
            return [];
      }

}
