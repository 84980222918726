import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import cejaReal from './../../images/home/box2/ceja_real_1.jpg';
import campestreReal from './../../images/home/box2/campestre_real_2.jpg';
import naturaReal from './../../images/home/box2/natura_real_3.jpg';
import praderaReal from './../../images/home/box2/pradera_real_4.jpg';
import citadelaDiSole from './../../images/home/box2/citadela_di_sole_5.jpg';
import dataProyectos from '../../infrastructure/data/proyectos.json'

const pics = [
	cejaReal,
	campestreReal,
	naturaReal,
	praderaReal,
	citadelaDiSole
];

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className="owl-next la la-angle-right" onClick={onClick} />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className="owl-nav">
			<div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
		</div>
	);
}

class Slider1 extends Component {
	render() {
		var settings = {
			arrows: false,
			centerMode: true,
			centerPadding: "500px",
			slidesToShow: 1,
			speed: 3000,
			navSpeed: 3000,
			infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 1920,
					settings: {
						slidesToShow: 1,
						centerPadding: "400px",
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 1,
						centerPadding: "250px",
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 1,
						centerPadding: "250px",
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerPadding: "200px",
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						centerPadding: "90px",
					}
				}
			]
		};
		return (

			<Slider className="amenities-carousel owl-carousel owl-btn-center-lr " {...settings}>
				{dataProyectos.map((item, index) => (

					<div className="items" key={index}>
						<div className="amenit-box" >
							<div className="media">
								<Link to={"#"} style={{ width: "100%" }}><img src={pics[index]} alt="{item.name}" style={{ width: "100%" }} /></Link>
							</div>
							<div className="info">
								<h5 className="title">
									<i className="ti-bookmark"></i>
									<span><Link to={"./proyecto/" + item.slug}>{item.name}</Link></span>
								</h5>
							</div>
						</div>
					</div>
				))}
			</Slider>


		)

	}

}

export default Slider1;

