import React,{Fragment, Component} from 'react';
import WOW from 'wowjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper} from 'simple-react-lightbox'; 
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import {ProDetail, ButtonPara, BlogPage} from './Data';
import {GallerySection} from './ProjectDetail1';
import ProyectosService from '../../../infrastructure/services/ProyectosService';
import {Link} from 'react-router-dom';


class ProjectDetail4 extends Component{
	componentDidMount(){
		new WOW.WOW().init();	
	}
	render(){
		const title = window.location.pathname.replace('/proyecto/','');
        //let data =  dataTrayectoria.filter(item => item.slug === title);
		let data =  ProyectosService(title);
		//console.log(JSON.stringify(data));

		//console.log(JSON.stringify(TrayectoriaService(title)));
		return(
			<Fragment>
				<Header />
				{data.map((data,index)=>(
				<div className="page-content bg-white" id="lightgallery">
					<PageTitle motherMenu={data.name} activeMenu={data.slug} />
					<SimpleReactLightbox>
					<SRLWrapper >
					{/* Inicio Gallery  */}
					<div className="section-full content-inner-2">
						<div className="container">
							<div className="row our-gallery mfp-gallery">
								<GallerySection gallery={data.images} />
							</div>
						</div>
					</div>
					{/* Fin Gallery  */}
					{/* Inicio Datos 2 columnas  */}							
						<section className="content-inner-2">
							<div className="container">		
								<div className="row">
									<div className="col-lg-6 m-b30">
										<p className="m-b0">{data.text1}</p>
									</div>
									<div className="col-lg-6 m-b30">
										<p className="m-b0">{data.text2}</p>
									</div>
								</div>
							</div>
					{/* FIN Datos 2 columnas  */}

					{/* Inicio Botones */}

							<div className="text-center">
								<Link to={"/proyecto/ceja-real"} className="btn btn-primary m-r20">CEJA REAL<span></span></Link>
								<Link to={{pathname: data.maps}} className="btn btn-primary" target="_blank">VER UBICACIÓN<span></span></Link>
							</div>
						</section>
					{/* FIN Botones */}

					{/*  Inicio de imagenes del Project*/}
						<div className="content-inner-1 mfp-gallery">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
										<div className="dlab-box gallery-box-2 m-b30">
											<BlogPage  imageType={data.images[3].image} />
										</div>
									</div>
									<div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
										<div className="dlab-box gallery-box-2 m-b30">
											<BlogPage  imageType={data.images[4].image} />
										</div>
									</div>
								{/* Inicio Datos del proyecto  */}
				
										<div className="container  content-inner-2">
											<div className="row section-head">
												<div className="col-lg-12" >
													<p>{data.location}</p>
													<h4 className="title">{data.name}</h4>
													<p>{data.subtitle}</p>
												</div>
												<div className="col-lg-12 align-self-center">
													<p className="m-b0">{data.text}</p>
												</div>
											</div> 
											<div className="row section-head">
												<div className="col-lg-12" >
													<h4 className="subtitle">ZONAS COMUNES</h4>
												</div>
												<div className="col-lg-12 align-self-center">
													<p>{data.zonas}</p>
												</div>
											</div> 

											<div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
												{data.iconos.map((data,index)=>(
													<div className="col-lg-4" key={index}>
														<div className="pro-details">{data.icono}{data.texto}</div>
													</div>
												))}	
											</div>
										</div>
							
						{/* Fin Datos del proyecto  */}
									<div className="col-lg-12 wow fadeIn content-inner-2" data-wow-duration="2s" data-wow-delay="0.6s">
										<div className="dlab-box gallery-box-2 m-b50">
											<BlogPage  imageType={data.images[5].image} />
										</div>
									</div>
								</div>
								<ButtonPara video={data.video} whatapp={data.whatapp} />
							</div>
						</div>
					{/* Inicio de imagenes del Project*/}
					</SRLWrapper>
					</SimpleReactLightbox>		
					{/* Content Area End  */}
					</div>	
))}	
				<Footer2 />		
			</Fragment>
		)
	}
}	


export default ProjectDetail4;
