import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import grid4 from './../../images/EventSectionProjects/campestre_real.png';
import grid3 from './../../images/EventSectionProjects/citadela_di_sole.png';
import grid2 from './../../images/EventSectionProjects/natura_real.png';
import grid1 from './../../images/EventSectionProjects/pradera_real.png';

const latestBlog = [
	{ link: "/proyecto/pradera-real", ubicacion: "La Ceja, Antioquia.", image: grid1, title: "Pradera Real, un proyecto en Ceja Real.", text: "Lotes de 104 m2 para casas de 140 m2. Zonas comunes y cercanía a todo lo que necesitas." },
	{ link: "/proyecto/natura-real", ubicacion: "La Ceja, Antioquia.", image: grid2, title: "Natura Real, un proyecto en Ceja Real.", text: "Etapa 1. Apartamentos de 55 m2 de área total. Vive la naturaleza dentro y fuera de tu hogar. Completas zonas comunes y cercanía a todo lo que necesitas." },
	{ link: "/proyecto/citadela-di-sole", ubicacion: "Santa Fe de Antioquia, Antioquia.", image: grid3, title: "Citadela Di Sole, un proyecto tipo Resort", text: "Apartasoles Suite, Deluxe, Premium- Spa ¡Disfruta de las vacaciones todo el año a sólo 40 minutos de Medellín! Completas zonas comunes tipo Resort." },
	{ link: "/proyecto/campestre-real", ubicacion: "La Ceja, Antioquia.", image: grid4, title: "Campestre Real, un proyecto en Ceja Real.", text: "Lotes desde 104 m2 para casas de 140 m2.	Zonas comunes y cercanía a todo lo que necesitas." },
]
/* function SampleNextArrow(props) {
  const { onClick } = props;
  return (
		<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick}/>
	</div>
  );
} */

class EventSlider extends Component {
	render() {
		var settings = {
			arrows: true,
			slidesToShow: 3,
			speed: 3000,
			navSpeed: 3000,
			infinite: true,
			autoplay: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		return (

			<Slider className="blog-carousel owl-carousel owl-btn-center-lr owl-btn-out " {...settings}>
				{latestBlog.map((item, index) => (
					<div className="item p-3" key={index}>
						<div className="dlab-card blog-grid">
							<div className="dlab-media"><img src={item.image} alt="" /></div>
							<div className="dlab-info">
								<div className="dlab-meta">
									<ul>
										<li className="post-date"><i className="las la-map-marker"></i><span>{item.ubicacion}</span></li>
									</ul>
								</div>
								<h3 className="dlab-title">
									<Link to={item.link}>{item.title}</Link>
								</h3>
								<p>{item.text}</p>
								<div className="dlab-readmore">
									<Link to={item.link} className="readmore"><i className="las la-plus"></i> Conocer mas</Link>
								</div>
							</div>
						</div>
					</div>
				))}
			</Slider>

		)

	}

}

export default EventSlider;