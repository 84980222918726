import img1 from '../../images/trayectoria/DEMO/img1.jpg'
import img2 from '../../images/trayectoria/DEMO/img2.jpg'
import img3 from '../../images/trayectoria/DEMO/img3.jpg'


export default function CitadelaDiSole() {
    const data =   
    [
        {
            "name": "Citadela Di Sole",
            "slug": "citadela-di-sole",
            "location": "Santa Fe de Antioquia, Antioquia.",
            "text": "Construimos en el SECTOR de Santa Fe de Antioquia, Antioquia; primera etapa en Unidad de Apartasoles de 56 y 76 m2 con zona recreativa tipo resort contando con piscinas, Juegos Infantiles, Cancha en Grama Sintética, Parque acuático infantil con Tobogán, Parque de hamacas y Zona de Mascotas para el descansar y disfrutar en familia o con amigos.",
            "images": [
                            {  image: img1,  }, 
                            {  image: img2,  }, 
                            {  image: img3,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Citadela Di Sole' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Santa Fe de Antioquia, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad de Apartasoles.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
        }
    ]
    return data;
    }
