import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Header from './../Layout/Header';
import Footer2 from './../Layout/Footer2';
import PageTitle from './../Layout/PageTitle';
import ExhibitionSlider1 from './../Element/ExhibitionSlider1';
import proyectos from "./../../infrastructure/data/proyectos.json"
import Slider from "react-slick";

//images/
import galery2 from './../../images/projects/TODOS/CAMPESTRE_REAL.png';
import galery1 from './../../images/projects/TODOS/CEJA_REAL.png';
import galery3 from './../../images/projects/TODOS/NATURA_REAL.png';
import galery4 from './../../images/projects/TODOS/PRADERA_REAL.png';
import galery5 from './../../images/projects/TODOS/SECTOR_DI_SOLE.png';

const galery = [
	galery1,
	galery2,
	galery3,
	galery4,
	galery5,
];

class CompanyExhibition extends Component {
	componentDidMount() {
		new WOW.WOW().init();
	}
	render() {
		const dataProyectos = proyectos.map((data, index) => {
			return (
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 ">
							<Slider className="exhibition-carousel owl-carousel owl-none m-b30 " >
								<div className="item"><img className="w-100" src={galery[index]} alt="{data.name}" /></div>
							</Slider>
						</div>
						<div className="col-lg-6 m-b30">
							<div className="section-head">
								<h2 className="title">{data.name}</h2>
								<p>{data.text1}</p>
								<p>{data.text2}</p>
								<p>{data.text3}</p>
							</div>
							<Link to={data.contact} className="btn btn-primary m-r10 m-b10">CONTACTAR</Link>
							<Link to={"/proyecto/" + data.slug} className="btn btn-primary m-b10">CONOCER MÁS</Link>
						</div>
					</div>
				</div>
			)
		})
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					<PageTitle motherMenu="Proyectos" activeMenu="Proyectos" />
					{/*  Inner Page Banner */}
					<section className="content-inner exhibition-bx  fondo-proyecto" data-content="Proyectos">
						{dataProyectos}
					</section>
				</div>
				<Footer2 />
			</Fragment>
		)
	}
}

function ExhibitionSection(proyecto) {
	return (
		<>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 ">
						<ExhibitionSlider1 />
					</div>
					<div className="col-lg-6 m-b30">
						<div className="section-head">
							<h2 className="title">{proyecto.name}</h2>
							<p>
								El Macroproyecto CEJA REAL ubicado en el Oriente Antioqueño- La Ceja. Es un proyecto Natural y Bike Friendly planeado con Apartamentos, Lotes, Casas, Clínica, Mall Comercial, Casa del Adulto Mayor, y una extensa ciclorruta integrada con más de 3 km.</p>
						</div>
						<Link to={"/contacto"} className="btn btn-primary m-r10 m-b10">CONTACTAR</Link>
						<Link to={"/proyectos/{data.slug}"} className="btn btn-primary m-b10">CONOCER MÁS</Link>
					</div>
				</div>
			</div>
		</>
	)
}
export { ExhibitionSection };

export default CompanyExhibition;