import img1 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_1.png'
import img2 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_2.png'
import img3 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_3.png'
import img4 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_4.png'
import img5 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_5.png'
import img6 from '../../images/trayectoria/ALTOS_DE_MONTEVERDE/Altos_de_Monteverde_Apartamentos_Monteria_Cordoba_Colombia_6.png'

export default function AltosdeMonteverde() {
    const data =   
    [
        {
            "name": "Altos de Monteverde",
            "slug": "altos-de-monteverde",
            "location": "Montería, Córdoba.",
            "text": "Construimos en el SECTOR de Montería, Córdoba; una Unidad Residencial de 264 apartamentos de 92 y 113 m2 en dos torres; con zonas comunes como piscina con playa interactiva, jacuzzi, turco, sauna, salón social, gimnasio, cancha de microfútbol en grama sintética, cancha de vóley playa, local comercial y juegos infantiles.",
            "images": [
                {  image: img1,  },
                {  image: img2,  },
                {  image: img3,  },  
                {  image: img4,  },  
                {  image: img5,  },  
                {  image: img6,  },
            ],
            "iconos":[
                {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Altos de Monteverde.' },	
                {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Montería, Córdoba.' },	
                {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad Residencial.' },	
                {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
              ]
}
]
return data;
}