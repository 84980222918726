import img1 from '../../images/trayectoria/CIUDADELA_SANTA FE/Ciudadela_Santa Fe_casas_Santa_Fe_de_Antioquia_1.png'
import img2 from '../../images/trayectoria/CIUDADELA_SANTA FE/Ciudadela_Santa Fe_casas_Santa_Fe_de_Antioquia_2.png'

export default function CiudadelaSantaFe() {
    const data =   
    [
        {
            "name": "Ciudadela Santa Fe",
            "slug": "Ciudadela-santa-fe",
            "location": "Santa Fe de Antioquia, Antioquia.",
            "text": "Construimos en el SECTOR de Santa Fe de Antioquia, Antioquia; un Conjunto Residencial de 186 Apartamentos de 43 m2, 53 m2 y 58 m2 en edificios de tres pisos; con 4 piscinas, tobogán, jacuzzi, parque de chorros, turco, solárium, playa en arena de cuarzo, cancha de microfútbol, cancha en grama sintética, cancha en grama natural, juegos infantiles, zonas verdes parqueaderos privados y de visitantes.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Ciudadela Santa Fe' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Santa Fe de Antioquia, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Conjunto Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
