import img1 from '../../images/trayectoria/DEMO/img1.jpg'
import img2 from '../../images/trayectoria/DEMO/img2.jpg'
import img3 from '../../images/trayectoria/DEMO/img3.jpg'


export default function AltosdeValparaiso() {
    const data =   
    [
        {
            "name": "Altos de Valparaíso",
            "slug": "altos-de-valparaiso",
            "location": "Sabaneta, Antioquia.",
            "text": "Construimos en el SECTOR de Sabaneta, Antioquia; una Unidad Residencial de Apartamentos con zonas comunes como piscina de adultos con horizonte perdido, piscina de niños, salón social, cancha de fútbol, cancha de golfito, zona de picnic, turco, sauna, juegos infantiles, parqueaderos y cuartos útiles",
            "images":  [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Altos de Valparaíso.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Sabaneta, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
