import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../images/logo.png';
import dataTrayectoria from '../../infrastructure/data/trayectoria.json'
import dataProyectos from '../../infrastructure/data/proyectos.json'
import datoSector from '../../infrastructure/data/datoSector.json'

class Header extends Component {
	componentDidMount() {

		// sidebar open/close

		var Navicon = document.querySelector('.navicon');
		var sidebarmenu = document.querySelector('.myNavbar ');

		function toggleFunc() {
			sidebarmenu.classList.toggle('show');
			Navicon.classList.toggle('open');
		}
		Navicon.addEventListener('click', toggleFunc);


		// Sidenav li open close
		var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
		for (var y = 0; y < navUl.length; y++) {
			navUl[y].addEventListener('click', function () { checkLi(this) });
		}


		function checkLi(current) {
			navUl.forEach(el => (current !== el) ? el.classList.remove('open') : '');

			setTimeout(() => {

				current.classList.toggle('open');

				/* alert(current.className.indexOf('open'));
					if(current.className.indexOf('open') == -1){
						current.classList.toggle('open');
						alert(2);
					}else{
					alert(current.parentNode);
						current.parentNode.classList.add('khelesh');
					} */

			}, 100);

		}
	}
	render() {
		return (
			<Fragment>
				{/*  header  */}
				<header className="site-header header-transparent">
					<HeaderContent />
				</header>
				{/*  header End  */}
			</Fragment>
		)
	}
}
function HeaderContent() {

	const menuTrayectoria = dataTrayectoria.map((data) => { return (<li><Link to={"/" + data.slug}>{data.name}</Link></li>) })
	const menuProyectos = dataProyectos.map((data) => { return (<li><Link to={"/proyecto/" + data.slug}>{data.name}</Link></li>) })
	return (
		<>
			<div className="top-bar">
				<div className="container-fluid">
					<div className="row d-flex justify-content-md-between justify-content-center align-items-center">
						<div className="dlab-topbar-left">
							<ul>
								<li><Link to={{ pathname: 'tel:' + datoSector.telefono }} target="_blank"><i className="la la-phone-volume"></i> {datoSector.telefono}</Link></li>
								<li><Link to={{ pathname: datoSector.googleMaps }} target="_blank"><i className="las la-map-marker"></i> {datoSector.localidad}</Link></li>
							</ul>
						</div>
						<div className="dlab-topbar-right">
							<ul>
								<li><Link to={{ pathname: datoSector.facebook }} className="site-button circle fa fa-facebook" target="_blank"></Link></li>
								<li><Link to={{ pathname: datoSector.instagram }} className="site-button circle fa fa-instagram" target="_blank"></Link></li>
								<li><Link to={{ pathname: datoSector.whatsApp }} className="fa fa-whatsapp" target="_blank"></Link></li>
								<li><Link to={{ pathname: datoSector.youtube }} className="fa fa-youtube" target="_blank"></Link></li>
								<li><Link to={{ pathname: 'mailto:' + datoSector.email }} target="_blank"> <i className="las la-envelope-open"></i> {datoSector.email}</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/*   main header  */}
			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container-fluid clearfix">
						{ /* website logo */}
						<div className="logo-header mostion logo-dark">
							<Link to={"/"}><img src={logo} alt="Sector Inmobiliario" /></Link>
						</div>
						{/* nav toggle button  */}
						<button className="navbar-toggler collapsed navicon justify-content-end " type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>
						{/*extra nav  */}
						<div className="extra-nav">
							<div className="extra-cell">
								<Link to={"/contacto"} target="_blank" className="btn btn-primary btnhover13">CONTÁCTANOS</Link>
							</div>
						</div>
						<div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
							<div className="logo-header d-md-block d-lg-none">
								<Link to={"/"}><img src={logo} alt="Sector Inmobiliario" /></Link>
							</div>
							<ul className="nav navbar-nav navbar">
								<li className="active"><Link to={"/"}>Inicio</Link>
									{/*extra nav  
								<ul className="sub-menu">

									<li><Link to={"./"}>Home 1</Link></li>
									<li><Link to={"index-2"}>Home 2</Link></li>
									<li><Link to={"index-3"}>Landing Page</Link></li>
									<li><Link to={"/about-us-1"}>About Us 1</Link></li>
									<li><Link to={"/about-us-2"}>About Us 2</Link></li>
									<li><Link to={"/company-exhibition"}>Company Exhibition</Link></li>
									<li><Link to={"/price-table"}>Price Table</Link></li>
									<li><Link to={"/company-history"}>Company History</Link></li>
									<li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
									<li><Link to={"/404-page"}>404 Error</Link></li>
									<li><Link to={"/coming-soon"}>Coming Soon</Link></li>
									<li><Link to={"/subscription-box-1"}>Subscription Box 1</Link></li>
									<li><Link to={"/subscription-box-2"}>Subscription Box 2</Link></li>
									<li><Link to={"/portfolio-1"}>Portfolio 1</Link></li>
									<li><Link to={"/portfolio-2"}>Portfolio 2</Link></li>
									<li><Link to={"/project-detail-1"}>Project Detail 1</Link></li>
									<li><Link to={"/project-detail-2"}>Project Detail 2</Link></li>
									<li><Link to={"/project-detail-3"}>Project Detail 3</Link></li>
									<li><Link to={"/project-detail-4"}>Project Detail 4</Link></li>
									<li><Link to={"/contact-us-1"}>Contact Us 1</Link></li>
									<li><Link to={"/contact-us-2"}>Contact Us 2</Link></li>
									<li><Link to={"/blog-grid"}>Blog Grid</Link></li>
									<li><Link to={"/blog-list"}>Blog List</Link></li>
									<li><Link to={"/blog-masonry"}>Blog Masonry</Link></li>
									<li><Link to={"/blog-details"}>Blog Details</Link></li>
								</ul>
								*/}
								</li>
								<li className="active"><Link to={"/proyectos"}>Proyectos<i className="fa fa-chevron-down"></i></Link>
									<ul className="sub-menu">
										<li><Link to={"/proyectos"}>Todos</Link></li>
										{menuProyectos}
									</ul>
								</li>
								<li><Link to={"/"}>Trayectoria<i className="fa fa-chevron-down"></i></Link>
									<ul className="sub-menu">
										{menuTrayectoria}
									</ul>
								</li>
								<li><Link to={"/nuestro-equipo"}>Nuestro Equipo</Link>
								</li>


							</ul>
							<div className="dlab-social-icon">
								<ul>
									<li><Link to={{ pathname: datoSector.facebook }} className="site-button circle fa fa-facebook" target="_blank"></Link></li>
									<li><Link to={{ pathname: datoSector.instagram }} className="site-button circle fa fa-instagram" target="_blank"></Link></li>
									<li><Link to={{ pathname: datoSector.whatsApp }} className="fa fa-whatsapp" target="_blank"></Link></li>
									<li><Link to={{ pathname: datoSector.youtube }} className="fa fa-youtube" target="_blank"></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export { HeaderContent };
export default Header;