import * as React from 'react';
import { Link } from 'react-router-dom';

const post = [
    { Title: "CAMPESTRE REAL", lote: "Lotes desde 104 m2", slug: "campestre-real" },
    { Title: "NATURA REAL", lote: "Apartamentos de 55 m2 y 75 m2", slug: "natura-real" },
    { Title: "PRADERA REAL", lote: "Lotes desde 104 m2", slug: "pradera-real" }
];

function Box3() {
    return (
        <section className="content-inner-2">
            <div className="container">
                <div className="pricingtable-row">
                    <div className="row">
                        {post.map((post) => (
                            <div className="col-sm-12 col-md-4 col-lg-4" >
                                <div className="pricingtable-wrapper table-option m-md-b30">
                                    <div className="pricingtable-inner">
                                        <div className="pricingtable-title">
                                            <h2>{post.Title}</h2>
                                        </div>
                                        <ul className="pricingtable-features">
                                            <li>{post.lote}</li>
                                        </ul>
                                        <div className="pricingtable-footer">
                                            <Link to={"/proyecto/" + post.slug} className="btn btn-primary">VER PROYECTO<span></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Box3;
