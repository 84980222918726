import img1 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_1.png'
import img2 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_2.png'
import img3 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_3.png'
import img4 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_4.png'
import img5 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_5.png'
import img6 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_6.png'
import img7 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_7.png'
import img8 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_8.png'
import img9 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_9.png'
import img10 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_10.png'
import img11 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_11.png'
import img12 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_12.png'
import img13 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_13.png'
import img14 from '../../images/trayectoria/BELLA_TERRA/Bella_Terra_14.png'



export default function BellaTerra() {
    const data =
        [
            {
                "name": "Bella Terra",
                "slug": "bella-terra",
                "location": "Envigado, Antioquia.",
                "text": "Construimos en el SECTOR de Envigado, Antioquia; una exclusiva Parcelación Residencial de 98 lotes urbanizados en el Alto de Las Palmas con áreas individuales desde 1.250 m2 hasta 5.650 m2; zonas comunes como gimnasio, salón social, 2 canchas de tenis de campo semi-profesionales, parque de lectura, cancha de microfútbol en grama sintética, sauna, turco, y 2 km de ciclorruta.",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                    { image: img8, },
                    { image: img9, },
                    { image: img10, },
                    { image: img11, },
                    { image: img12, },
                    { image: img13, },
                    { image: img14, },
                ],
                "iconos": [
                    { icon: <i className="ti ti-user" />, title: 'PROYECTO', subtitle: 'Bella Terra.' },
                    { icon: <i className="ti ti-location-pin" />, title: 'SECTOR', subtitle: 'Envigado, Antioquia.' },
                    { icon: <i className="ti ti-home" />, title: 'TIPO', subtitle: 'Parcelación Residencial.' },
                    { icon: <i className="ti ti-ruler-alt-2" />, title: 'CONSTRUYE', subtitle: 'Ingearco y cia s.a.s' },
                ]
            }
        ]
    return data;
}
