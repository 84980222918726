import img1 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_1.png'
import img2 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_2.png'
import img3 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_3.png'
import img4 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_4.png'
import img5 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_5.png'
import img6 from '../../images/trayectoria/MIRADOR_DEL_CACIQUE/SECTOR_MIRADOR_DEL_CACIQUE_6.png'


export default function MiradordelCacique() {
    const data =   
    [
        {
            "name": "Mirador del Cacique",
            "slug": "mirador-del-cacique",
            "location": "San Jerónimo, Antioquia.",
            "text": "Construimos en el SECTOR de San Jerónimo, Antioquia; el urbanismo de 66 lotes desde 700 m2 hasta 1.300 m2 con Portería, Juegos Infantiles y Cancha Polideportiva.",
            "images":  [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Mirador del Cacique' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'San Jerónimo, Antioquia' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Parcelación Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
        }
    ]
    return data;
    }
