import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';

import Header2 from './../../Layout/Header2';
import Footer2 from './../../Layout/Footer2';
import SideNav from './../../Element/SideNav';
import Slider1 from './../../Element/Slider1';
import SponsorOwl from '../Aboutus/SponsorOwl';
//Images
import SliderImg from './../../../images/main-slider/fachada_dia_campestre_real_2000x1080.png';
import bgimg from './../../../images/bg-view.png';
import NuestraTrayectoria from './NuestraTrayectoria';

class Index1 extends React.Component {
	componentDidMount() {
		new WOW.WOW().init();

		var GalleryCategory = document.querySelectorAll('.gallery-category .items');
		var GalelryMedia = document.querySelectorAll('.gallery-img img');

		var fch = [].slice.call(GalleryCategory);
		var fcMedia = [].slice.call(GalelryMedia);


		for (var y = 0; y < fch.length; y++) {
			fch[y].addEventListener('mouseenter', function () {
				galleryActive(this);
			});
		}

		function galleryActive(current) {
			fcMedia.forEach(el => el.classList.remove('active'));

			setTimeout(() => {
				var dataImageBx = current.getAttribute('data-image-bx');
				document.querySelector('#' + dataImageBx).classList.add('active');
			}, 100);
		}
	}
	render() {
		return (
			<Fragment>

				<div className="sidenav-list">
					<SideNav />
				</div>

				<Header2 />
				{/* <!-- Main Slider --> */}
				<div className="page-content bg-white">
					<div className="banner-three " style={{ backgroundImage: "url(" + SliderImg + ")", backgroundSize: "cover" }} data-content="HOME" id="sidenav_home" >
						<div className="container">
							<div className="row align-items-center banner-inner " >
								<div className="col-md-6">
									<div className="content-blog">
										<div className="banner-content">
											<h1 className=" title  m-b20">Transformamos un<br />Sector en un gran<br />desarrollo  para el futuro.  </h1>
											<Link to={"/proyectos"} className=" btn btn-primary">VER PROYECTOS</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Main Slider End--> */}

					{/* Section-1  Start*/}
					<section className="amenities-area">
						<Slider1 />
					</section>
					{/* Section-1  End*/}


					{/* Section-3 */}

					<section className="content-inner about-box" data-content="Sector" id="sidenav_aboutUs">
						<div className="about-bg"></div>
						<div className="container">
							<div className="row">
								<div className="col-md-7 col-lg-6">
									<div className="section-head">
										<h2 className="title" >SOBRE NOSOTROS</h2>
										<div className="dlab-separator bg-primary " ></div>
										<p >
											Somos un grupo de personas apasionadas por lo que hacemos. Promocionar, gerenciar, vender y construir soluciones inmobiliarias en sectores colombianos, es parte de nuestras vidas.
										</p>
										<p>
											Nuestra mayor satisfacción está en ver la transformación de un SECTOR al convertirlo en un GRAN DESARROLLO para el futuro.
										</p>
									</div>
									<Link to={"/nuestro-equipo"} className="btn btn-primary " >VER MÁS</Link>
								</div>
								<div className="col-md-5 col-lg-6"></div>
							</div>
						</div>
					</section>
					<NuestraTrayectoria />
					<img src={bgimg} className="bg-view" alt="" />
					<SpondorSection />
				</div>
				<Footer2 />
			</Fragment>
		)
	}
}
function SpondorSection() {
	return (
		<>
			<section className="content-inner-1" data-content="NUESTRO EQUIPO" id="sidenav_soponser">
				<div className="container">
					<div className="section-head text-center">
						<p>SECTOR INMOBILIARIO</p>
						<h2 className="title">NUESTRO EQUIPO</h2>
					</div>
					<SponsorOwl />
				</div>
			</section>
		</>
	)
}
export default Index1;