import img1 from '../../images/proyectos/PRADERA_REAL/BOX 2 pradera_real_fachada.jpg'
import img2 from '../../images/proyectos/PRADERA_REAL/BOX 3 ceja_real.png'
import img3 from '../../images/proyectos/PRADERA_REAL/BOX 4 características_ceja_real.png'
import img4 from '../../images/proyectos/PRADERA_REAL/BOX 5 pradera_real_interior_casa.jpg'
import img5 from '../../images/proyectos/PRADERA_REAL/BOX 6 pradera_real_terraza.jpg'
import img6 from '../../images/proyectos/PRADERA_REAL/BOX 6 zonas_comunes_pradera_real.jpg'
import img7 from '../../images/proyectos/PRADERA_REAL/BOX 8 terraza_jardín_pradera_real.jpg'
import img8 from '../../images/proyectos/PRADERA_REAL/BOX 9 portería_pradera_real_Mesa de trabajo 1 copia.jpg'
import img9 from '../../images/proyectos/PRADERA_REAL/BOX 10 pradera_real_fachada_noche.jpg'

export default function PraderaReal() {
    const data =
        [
            {
                "name": "Pradera Real",
                "slug": "pradera-real",
                "subtitle": "LOTES DE 104 m2.",
                "zonas": "El proyecto te brindará la oportunidad de disfrutar con tu familia o amigos de amplias zonas comunes como:",
                "location": "La Ceja, Antioquia, Colombia.",
                "text": "PRADERA REAL está compuesto por 207 lotes divididos en dos etapas, cada uno de 104 m2. Aquí, podrás construir casas de 140 m2 aproximadamente bajo un diseño eco-amigable enfocado en el uso de luz natural, contemplación de doble altura en espacio social y un generoso frente de 8 metros con dos parqueaderos individuales y parqueaderos de bicicletas u opción de modificación a un parqueadero y una habitación en primera planta. También contarás con un gran jardín interior de 20 m2 aproximadamente, en el cual tendrás un espacio donde desarrollar tu propia eco-huerta, zona húmeda, BBQ u otras comodidades si así lo quisieras.",
                "text1": "El macroproyecto CEJA REAL, Natural y Bike Friendly con más de 3 km de ciclorruta integrada, un entorno planeado con Centro de Atención Médico, Mall Comercial, Casa del Adulto Mayor, Estación de Servicio y viviendas, se encuentra en construcción de su primer proyecto llamado PRADERA REAL compuesto por 207 lotes de 104 m2 en el cual podrás construir casas de 140 m2 aproximadamente con 2 parqueaderos individuales y un jardín interno de 20 m2 aprox.",
                "text2": "Cuenta con una privilegiada ubicación en medio de la Nueva Circunvalar Sur doble Calzada con 4 vías de acceso: Vía Medellín, Vía Llanogrande, Vía Unión  y Vía Abejorral. Adicionalmente, tendremos un acceso por la Casa de Retiros Espirituales “La Colombiere”. Encuéntranos en Waze o en Google Maps como Ceja Real y atrévete a conocer este paraíso del oriente antioqueño.",
                "video": "https://www.youtube.com/channel/UChnRgtI40lFiKVbQa4XN3jA",
                "whatapp": "https://api.whatsapp.com/send?phone=573115101010&text=Hola,%20me%20gustaria%20recibir%20mas%20informaci%C3%B3n%20y%20comunicarme%20con%20uno%20de%20sus%20asesores",
                "maps": "https://www.google.com/maps/dir/6.2012888,-75.5789893/ceja+real/@6.1214679,-75.5717503,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8e46975278687855:0x3f2cf8083a36eaab!2m2!1d-75.4269563!2d6.017723",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                    { image: img8, },
                    { image: img9, },
                ],
                "iconos": [
                    { icono: <i className="ti ti-bookmark" />, texto: "Portería con vigilancia 24h " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cuarto de Monitoreo" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Gimnasio" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Salón Social" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Coworking" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Supermarket" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha en Grama Sintética Fútbol 5" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha Polideportiva" },
                    { icono: <i className="ti ti-bookmark" />, texto: "2 Parques de Mascotas" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Juegos Infantiles" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Zonas verdes" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Oficina de Administración" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cuarto de Basuras" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parqueaderos de Visitantes" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parqueaderos de Bicicletas" },
                ]
            }
        ]
    return data;
}