import img1 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_1.png'
import img2 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_2.png'
import img3 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_3.png'
import img4 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_4.png'
import img5 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_5.png'
import img6 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_6.png'
import img7 from '../../images/trayectoria/CITADELA_DI_TERRA/Sector_Citadela_Di_Terra_El_Retiro_Apartamentos_7.png'

export default function CitadelaDiTerra() {
    const data =   
    [
        {
            "name": "Citadela Di Terra",
            "slug": "citadela-di-terra",
            "location": "El Retiro, Antioquia.",
            "text": "Construimos en el SECTOR de El Retiro, Antioquia; una Unidad Residencial de 320 Apartamentos de 84 y 92 m2 con circuito de spa, piscina de nado y de niños climatizadas, solárium, lago artificial, gimnasio, salón lounge, salón social, portería, parqueaderos, cuartos útiles y juegos infantiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },   
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Citadela Di Terra' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'El Retiro, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'El Retiro, Antioquia.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
