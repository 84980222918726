import React,{Fragment, Component} from 'react';
import WOW from 'wowjs';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import {ProDetail} from './Data';
import Detail3Owl from './Detail3Owl';
import TrayectoriaService from '../../../infrastructure/services/TrayectoriaService';

class ProjectDetail3 extends Component{
	componentDidMount(){
		new WOW.WOW().init();	
	}
	render(){
		const title = window.location.pathname.replace('/','');
        //let data =  dataTrayectoria.filter(item => item.slug === title);
		let data =  TrayectoriaService(title);
		//console.log(JSON.stringify(data));

		//console.log(JSON.stringify(TrayectoriaService(title)));
		return(
			<Fragment>
				<Header />
				{data.map((data,index)=>(
				<div className="page-content bg-white" >
					{/*  banner  */}
					<PageTitle motherMenu={data.name} activeMenu={data.slug} />
					{/*  Project Img  */}
					{/*  Info Carousel  */}
					<div className="section-full content-inner-2 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
						<div className="container-fluid">
							<Detail3Owl gallery={data.images}/>
						</div>
					</div>
					{/*  Info Carousel */}
					
					{/*  Project Info  */}
					<section className="section-full content-inner">
					<div className="container">
						<div className="section-head">
							<span>{data.location}</span>
							<h4 className="title">{data.name}</h4>
						</div>
						<p className="m-b30">{data.text}</p>
						<div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
						{data.iconos.map((data,index)=>(
							<div className="col p-lr0" key={index}>
								<div className="pro-details">{data.icon}<strong>{data.title}</strong> {data.subtitle}</div>
							</div>
						))}	
						</div>
					</div>
				</section>
					{/*  Project Info  End*/}
				</div>	
							))}	
				<Footer2 />		
			</Fragment>
		)
	}
}	

export default ProjectDetail3; 