import CampestreReal from "../entities/CampestreReal"
import NaturaReal from "../entities/NaturaReal"
import PraderaReal from "../entities/PraderaReal"
import CitadelaDiSole from "../entities/CitadelaDiSole2"

export default function ProyectosService(param) {
    
    switch(param) {
        case 'campestre-real':
            return CampestreReal();
        case 'natura-real':
            return NaturaReal();
        case 'pradera-real':
            return PraderaReal();
        case 'citadela-di-sole':
            return CitadelaDiSole();
        default:
            return [];
      }

}
