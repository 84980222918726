import img1 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_1.png'
import img2 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_2.png'
import img3 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_3.png'
import img4 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_4.png'
import img5 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_5.png'
import img6 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_6.png'
import img7 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_7.png'
import img8 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_8.png'
import img9 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_9.png'
import img10 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_10.png'
import img11 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_11.png'
import img12 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_12.png'
import img13 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_13.png'
import img14 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_14.png'
import img15 from '../../images/trayectoria/CITADELA_DI_AQUA/Sector_Antioquia_Premium_15.png'

export default function citadelaDiAqua() {
    const data =   
    [
        {
            "name": "Citadela Di Aqua",
            "slug": "citadela-di-aqua",
            "location": "San Jerónimo, Antioquia.",
            "text": "Construimos en el SECTOR de San Jerónimo, Antioquia; una Unidad de 192 Apartasoles tipo Resort en tres imponentes torres de 8 pisos con 5 piscinas en cascada, tobogán, parque acuático para niños, parque de chorros, kioskos, parque de juegos infantiles, cancha de fútbol, cancha polideportiva, 2 solárium, turco, sauna y juegos infantiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },  
                            {  image: img8,  },  
                            {  image: img9,  },  
                            {  image: img10,  },  
                            {  image: img11,  },  
                            {  image: img12,  },  
                            {  image: img13,  },  
                            {  image: img14,  },  
                            {  image: img15,  },  
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Citadela Di Aqua' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'San Jerónimo, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad de Apartasoles.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
        }
    ]
return data;
}
