import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Header from '../../Layout/Header';
import Footer2 from '../../Layout/Footer2';
import PageTitle from '../../Layout/PageTitle';
import proyectos from "../../../infrastructure/data/proyectos.json"
//images 
import Img1 from '../../../images/projects/ceja-real/Ceja_Real_Macroproyecto.png';
//componentes
import Box3 from './components/Box3';
import BoxSponsorCejaReal from './components/BoxSponsorCejaReal';
import EventSection from './components/EventSections';
import MapBox from '../../Element/MapBox';


function cejaReal(proyecto) {
	return proyecto.slug === 'ceja-real';
}

class PriceTable extends Component {
	componentDidMount() {
		new WOW.WOW().init();
	}
	render() {
		const data = proyectos.find(cejaReal);
		return (
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					{/*  banner  */}
					<PageTitle motherMenu="Ceja Real" activeMenu="proyecto/ceja-real" />
					{/*  Our Gallery  */}
					<section className="content-inner-2" data-content="Sector">
						<div className="container">
							<div className="section-head text-center">
								<h2 className="title">{data.text1}</h2>
							</div>
							<div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12 m-b30 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
									<Link to={"#"}><img className="full-img" src={Img1} alt={data.text1} /></Link>
								</div>
								<div className="col-lg-12 col-md-12 col-sm-12">
									<div className="max-w800 m-auto">
										<p className="m-b30">{data.text4}</p>
										<p className="m-b30">{data.text5}</p>
										<div className='text-center'>
											<Link to={{ pathname: data.contact }} className="btn btn-primary" target="_blank">¡QUIERO SABER MÁS!</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<BoxSponsorCejaReal />
					<Box3 />
					<MapBox />
				</div>
				<Footer2 />
			</Fragment>
		)
	}
}
export default PriceTable;