import img1 from '../../images/trayectoria/CAMINOS_DE_SAN_PATRICIO/Sector_Caminos de_San_Patricio_apartamentos_Centro_Medellin_Antioquia_Colombia.png'

export default function CaminosdeSanPatricio() {
    const data =   
    [
        {
            "name": "Caminos de San Patricio",
            "slug": "caminos-de-san-patricio",
            "location": "Medellín, Antioquia.",
            "text": "Construimos en el SECTOR de Medellín, Antioquia; un Edificio Residencial de 210 Apartamentos con portería, parqueaderos cubiertos y cuartos útiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img1,  },
                            {  image: img1,  },  
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Caminos de San Patricio.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Medellín, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Edificio Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
