import img1 from '../../images/trayectoria/JARDINES_DE_LA_PLAZA/Sector_Jardines_de_la_plaza_1.png'

export default function JardinesdelaPlaza() {
    const data =   
    [
        {
            "name": "Jardines de la Plaza",
            "slug": "jardines-de-la-plaza",
            "location": "Medellín, Antioquia.",
            "text": "Construimos en el SECTOR de Medellín, Antioquia; un Edificio Residencial de 211 apartamentos con portería y parqueaderos.",
            "images": [
                            {  image: img1,  }, 
                            {  image: img1,  }, 
                            {  image: img1,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Jardines de la Plaza.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Medellín, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Edificio Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }

