import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo1 from './../../images/logo/footer/ingearco.png';
import logo2 from './../../images/logo/footer/sector_inmobiliario.png';
import logo3 from './../../images/logo/footer/sector_constructor.png';
import logo4 from './../../images/logo/footer/sector_comercial.png';
import logowhite from './../../images/logo-white.png';
import datoSector from '../../infrastructure/data/datoSector.json'

class Footer extends Component {
	render() {
		return (
			<Fragment>
				<footer className="site-footer" id="sidenav_footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
									<Footercol1 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
									<Footercol2 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
									<Footercol3 />
								</div>
							</div>
						</div>
					</div>
					{/*  footer bottom part */}
					<div className="footer-bottom">
						<Footerbottom />
					</div>
				</footer>
			</Fragment>
		)
	}
}
function Footercol1() {
	return (
		<>
			<div className="widget widget_about">
				<div className="footer-logo">
					<Link to="/"><img src={logowhite} alt="" /></Link>
				</div>
				<p>Promocionar, gerenciar, vender y construir soluciones inmobiliarias en sectores  colombianos es parte de nuestras vidas.</p>
				<div className="dlab-social-icon">
					<ul>
						<li><Link to={{ pathname: datoSector.facebook }} className="fa fa-facebook mr-1" target="_blank"></Link></li>
						<li><Link to={{ pathname: datoSector.instagram }} className="fa fa-instagram" target="_blank"></Link></li>
						<li><Link to={{ pathname: datoSector.whatsApp }} className="fa fa-whatsapp" target="_blank"></Link></li>
						<li><Link to={{ pathname: datoSector.youtube }} className="fa fa-youtube" target="_blank"></Link></li>
					</ul>
				</div>
			</div>
		</>
	)
}

function Footercol2() {
	return (
		<>
			<div className="widget">
				<h5 className="footer-title">CONTÁCTANOS</h5>
				<ul className="contact-info-bx">
					<li><Link to={{ pathname: datoSector.googleMaps }} target="_blank" className='linkBlanco'><i className="las la-map-marker"></i>{datoSector.direccion}</Link></li>
					<li><Link to={{ pathname: 'tel:' + datoSector.telefono }} target="_blank" className='linkBlanco'><i className="las la-phone-volume"></i>{datoSector.telefono}</Link></li>
					<li><Link to={{ pathname: 'mailto:' + datoSector.email }} target="_blank" className='linkBlanco'><i className="las la-envelope-open"></i>{datoSector.email}</Link></li>
				</ul>
			</div>
		</>
	)
}

function Footercol3() {
	return (
		<>
			<div className="widget widget-logo">
				<h5 className="footer-title">NUESTRO EQUIPO</h5>
				<ul>
					<li><Link to={"#"}><img src={logo1} alt="" /></Link></li>
					<li><Link to={"#"}><img src={logo2} alt="" /></Link></li>
					<li><Link to={"#"}><img src={logo3} alt="" /></Link></li>
					<li><Link to={"#"}><img src={logo4} alt="" /></Link></li>
				</ul>
			</div>
		</>
	)
}

function Footerbottom() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-12 text-md-left text-center"> <span>© 2023 Sector. All Right Reserved</span> </div>
					<div className="col-md-6 col-sm-12 text-md-right text-center">
						<div className="widget-link ">
							<ul>
								<li><Link to={"/nuestro-equipo"}> Nuestro Equipo</Link></li>
								<li><Link to={"/proyectos"}> Proyectos</Link></li>
								<li><Link to={"/contacto"}> CONTÁCTANOS</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export { Footercol1, Footercol2, Footercol3, Footerbottom };
export default Footer;