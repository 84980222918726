import img1 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_1.png'
import img2 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_2.png'
import img3 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_3.png'
import img4 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_4.png'
import img5 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_5.png'
import img6 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_6.png'
import img7 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_7.png'
import img8 from '../../images/trayectoria/CIUDADELA_DEL_SOL/CIUDADELA_DEL_SOL_8.png'

export default function CiudadeladelSol() {
    const data =   
    [
        {
            "name": "Ciudadela del Sol",
            "slug": "ciudadela-del-sol",
            "location": "San Jerónimo, Antioquia.",
            "text": "Construimos en el SECTOR de San Jerónimo, Antioquia; una Unidad de Casas con 11 piscinas de adultos y niños, parque de lectura, cancha de vóleibol, cancha de tenis de campo, cancha polideportiva, solárium, senderos y juegos infantiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },  
                            {  image: img8,  },  
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Ciudadela del Sol.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'San Jerónimo, Antioquia' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad de Casas.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
        }
    ]
    return data;
    }
