import React, { Fragment, Component } from 'react';
import WOW from 'wowjs';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import datoSector from '../../../infrastructure/data/datoSector.json'
import { Redirect } from "react-router-dom"
import bgimg from './../../../images/bg-view.png';
import { Link } from 'react-router-dom';


class Contacto extends Component {

    componentDidMount() {
        new WOW.WOW().init();
    }
    constructor(props) {
        super(props);
        this.state = {
            dzFirstName: '',
            dzLastName: '',
            dzOPhone: '',
            dzEmail: '',
            dzMessage: '',

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        //alert('A list was submitted: ' + this.state.formvalue);
        var today = new Date();
        console.log(today);
        event.preventDefault();
        fetch('https://pdypoda7p3.execute-api.us-east-2.amazonaws.com/staging/send-email', {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
            },
            body: JSON.stringify({
                nombre: this.state.dzFirstName,
                apellido: this.state.dzLastName,
                telefono: this.state.dzOPhone,
                email: this.state.dzEmail,
                mensaje: this.state.dzMessage,
                fecha: today
            })
        })
            .then(res => res.json())
            .then(data =>
                alert('“¡Hola! Hemos recibido exitosamente tu mensaje, gracias por comunicarte con Sector Inmobiliario…”'),
            )
            .catch(err => console.log(err))

    }

    render() {

        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white">
                    <PageTitle motherMenu="CONTÁCTANOS" activeMenu="contacto" />
                    {/*  Inner Page Banner */}
                    <section className="content-inner-1" data-content="CONTÁCTANOS">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-5 col-sm-12">
                                    <div className="section-head m-b30">
                                        <h2 className="title">QUEREMOS HABLAR CONTIGO</h2>
                                        <div className="dlab-separator bg-primary"></div>
                                        <h5 className="title-small">CONTÁCTANOS</h5>
                                    </div>
                                    <ul className="contact-question">
                                        <li>
                                            <Link to={{ pathname: datoSector.googleMaps }} target="_blank">
                                                <i className="fa fa-map-marker"></i>
                                                <h4 className="title">Visítanos</h4>
                                                <p>{datoSector.direccion}</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: 'mailto:' + datoSector.email }} target="_blank">
                                                <i className="fa fa-envelope-o"></i>
                                                <h4 className="title">Envíanos un Correo Electrónico</h4>
                                                <p>{datoSector.email}</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: datoSector.whatsApp }} target="_blank">
                                                <i className="fa fa-whatsapp"></i>
                                                <h4 className="title">Escríbenos por WhatsApp</h4>
                                                <p>{datoSector.telefono}</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={{ pathname: 'tel:' + datoSector.telefono }} target="_blank">
                                                <i className="fa fa-phone"></i>
                                                <h4 className="title">Llámanos</h4>
                                                <p>{datoSector.telefonoFijo}</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-8 col-md-7 col-sm-12 m-b30">
                                    <form className="contact-box dzForm p-a30 border-1" onSubmit={this.handleSubmit}>
                                        <h3 className="title-box">NOS INTERESA CONOCER TU OPINIÓN</h3>
                                        <div className="dzFormMsg m-b20"></div>
                                        <input type="hidden" value="Contact" name="dzToDo" />
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="dzFirstName" type="text" required className="form-control" placeholder="Nombre" value={this.state.dzFirstName} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="dzLastName" type="text" required className="form-control" placeholder="Apellido" value={this.state.dzLastName} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="dzOPhone" type="text" required className="form-control" placeholder="Teléfono" value={this.state.dzOPhone} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input name="dzEmail" type="email" className="form-control" required placeholder="Correo Electrónico" value={this.state.dzEmail} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Mensaje" value={this.state.dzMessage} onChange={this.handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div>
                        <img src={bgimg} className="bg-view" alt="" />
                    </div>
                </div>
                <Footer2 />
            </Fragment>
        )
    }
}
export default Contacto;