import React, { Fragment, Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Masonry from 'react-masonry-component';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';

//images
import box1 from './../../../images/portfolio/portfolio-box1/pic1.jpg';
import box2 from './../../../images/portfolio/portfolio-box1/pic2.jpg';
import box3 from './../../../images/portfolio/portfolio-box1/pic3.jpg';
import box4 from './../../../images/portfolio/portfolio-box1/pic4.jpg';
import box5 from './../../../images/portfolio/portfolio-box1/pic5.jpg';
import box6 from './../../../images/portfolio/portfolio-box1/pic6.jpg';
import box7 from './../../../images/portfolio/portfolio-box1/pic7.jpg';
import box8 from './../../../images/portfolio/portfolio-box1/pic8.jpg';
import box9 from './../../../images/portfolio/portfolio-box1/pic9.jpg';
import box10 from './../../../images/portfolio/portfolio-box1/pic10.jpg';
import box11 from './../../../images/portfolio/portfolio-box1/pic11.jpg';
import box12 from './../../../images/portfolio/portfolio-box1/pic12.jpg';

import dataTrayectoria from '../../../infrastructure/data/trayectoria.json'


// Masonry section
const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

class Trayectoria extends Component {
    componentDidMount() {
        new WOW.WOW().init();
    }
    render() {
        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white">
                    {/*  banner  */}
                    <PageTitle motherMenu="Trayectoria" activeMenu="Trayectoria" />
                    {/*  Section-1 Start  */}
                    <TrayectoriaItem />

                </div>
                <Footer2 />
            </Fragment>
        )
    }
}

function TrayectoriaItem() {
    return (
        <>
            <section className="content-inner-2" data-content="TRAYECTORIA">
                <div className="container">
                    <div className="section-head text-center">
                        <p>SECTOR INMOBILIARIO</p>
                        <h2 className="title m-b10">TRAYECTORIA</h2>
                    </div>
                </div>
                <div className="clearfix">
                    <ul className="gallery mfp-gallery text-center portfolio-bx p-l0 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                        <Masonry
                            className={'my-gallery-class'} // default ''
                            options={masonryOptions} // default {}
                            disableImagesLoaded={false} // default false
                            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                            imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                            {dataTrayectoria.map((item, index) => (

                                <li data-category="abstract" className="card-container col-lg-3 col-md-4 col-sm-6 p-lr0 abstract" key={index}>
                                    <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1">
                                        <img src={box2} alt={item.name} />
                                        <div className="overlay-bx">
                                            <div className="portinner">
                                                <span>{item.location}</span>
                                                <h3 className="port-title"><Link to={"project-detail-1"}>{item.name}</Link></h3>
                                                <Link to={"/" + item.slug} className="btn btn-primary m-t15">Ver Proyecto</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </Masonry>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default Trayectoria;