import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index1 from './Pages/Homepage/Index1';
import CompanyExhibition from './Pages/CompanyExhibition';
import ErrorPage from './Pages/ErrorPage';
import ProjectDetail3 from './Pages/ProjectDetail/ProjectDetail3';
import ProjectDetail4 from './Pages/ProjectDetail/ProjectDetail4';
import dataTrayectoria from '../infrastructure/data/trayectoria.json'
import dataProyectos from '../infrastructure/data/proyectos.json'//Proyectos
import CejaReal from './Pages/Proyectos/CejaReal';
import CampestreReal from './Pages/Proyectos/CampestreReal';
import NaturaReal from './Pages/Proyectos/NaturaReal';
import PraderaReal from './Pages/Proyectos/PraderaReal'
import CitadelaDiSole from './Pages/Proyectos/CitadelaDiSole';
// Landings
import Trayectoria from './Pages/Proyectos/Trayectoria';
import NuestroEquipo from './Pages/Proyectos/NuestroEquipo'
import Contacto from './Pages/Proyectos/Contacto';
//ScrollToTop
import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component {
	render() {
		const rutasTrayectoria = dataTrayectoria.map((data) => { return (<Route path={"/" + data.slug} exact component={ProjectDetail3} />) })
		const rutasProyectos = dataProyectos.map((data) => { return (<Route path={"/proyecto/" + data.slug} exact component={ProjectDetail4} />) })
		return (
			<BrowserRouter basename="/">
				<div className="page-wraper">
					<Switch>
						<Route path='/' exact component={Index1} />
						<Route path='/proyectos' exact component={CompanyExhibition} />
						<Route path='/proyecto/ceja-real' exact component={CejaReal} />
						<Route path='/proyecto/campestre-real' exact component={CampestreReal} />
						<Route path='/proyecto/natura-real' exact component={NaturaReal} />
						<Route path='/proyecto/pradera-real' exact component={PraderaReal} />
						<Route path='/proyecto/citadela-di-sole' exact component={CitadelaDiSole} />
						<Route path='/nuestro-equipo' exact component={NuestroEquipo} />
						<Route path='/contacto' exact component={Contacto} />
						<Route path='/404-page' exact component={ErrorPage} />
						<Route path='/proyecto' exact component={ProjectDetail4} />
						{rutasTrayectoria}
						{rutasProyectos}
					</Switch>
				</div>
				<ScrollToTop />
			</BrowserRouter>
		)
	}

}

export default Markup;