import React, { Component } from 'react'
import Slider from "react-slick";
import logo1 from '../../../../images/projects/ceja-real/CAMPESTRE_REAL_LOTES_LA_CEJA.png';
import logo2 from '../../../../images/projects/ceja-real/NATURA_REAL.png';
import logo3 from '../../../../images/projects/ceja-real/PRADERA_REAL.png';
import logo4 from '../../../../images/projects/ceja-real/PROYECTOS_EN_CEJA_REAL.png';

const aboutBlog = [
    { image: logo4, alt: "PROYECTOS EN CEJA REAL" },
    { image: logo1, alt: "CAMPESTRE REAL LOTES LA CEJA" },
    { image: logo2, alt: "NATURA REAL" },
    { image: logo3, alt: "PRADERA REAL" },
]

/* function SampleNextArrow(props) {
  const { onClick } = props;
  return (
        <div className="owl-nav">
        <div className="owl-next la la-angle-right "  onClick={onClick}/>
    </div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
   <div className=" owl-prev la la-angle-left " onClick={onClick}/>
    </div>
  );
}
 */
class Detail3Owl extends Component {

    render() {
        var settings = {
            dots: false,
            arrows: false,
            slidesToShow: 4,
            infinite: true,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        };
        return (
            <>
                <div className="content-inner-2">
                    <div className="container">
                        <Slider className="client-logo owl-carousel owl-none" {...settings}>
                            {aboutBlog.map((item, index) => (
                                <div className="item">
                                    <div className="client-logo-inner">
                                        <img src={item.image} alt={item.alt} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </>
        )
    }
}
export default Detail3Owl;