//poblado-del-hato
import img1 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_1.png'
import img2 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_2.png'
import img3 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_3.png'
import img4 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_4.png'
import img5 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_5.png'
import img6 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_6.png'
import img7 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_7.png'
import img8 from '../../images/trayectoria/POBLADO_DEL_HATO/POBLADO_DEL_HATO_8.png'

export default function pobladoDelHato() {
    const data =   
    [
      {
        "name": "Poblado del Hato",
        "slug": "poblado-del-hato",
        "location": "San Jerónimo, Antioquia.",
        "text": "Construimos en el SECTOR de San Jerónimo, Antioquia; un Conjunto Residencial con 65 casas de 147 y 172 m2  en lotes individuales de 225 y 370 m2 en un área total del lote de 26.000 m2; con amplias zonas comunes: 4 piscinas, jacuzzi, sauna, turco, playa en arena de cuarzo, kioscos, parque con chorros de agua, cancha de microfútbol, cancha de vóley playa.",
        "images": [
              {  image: img1,  },
              {  image: img2,  },
              {  image: img3,  },  
              {  image: img4,  },  
              {  image: img5,  },  
              {  image: img6,  },  
              {  image: img7,  },  
              {  image: img8,  }  
        ],
        "iconos":[
          {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Poblado del Hato' },	
          {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'San Jerónimo, Antioquia.' },	
          {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Conjunto Residencial.' },	
          {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
      ]
      }
  ]
  return data;
}

