import img1 from '../../images/proyectos/NATURA_REAL/Box 2 Natura Real.png'
import img2 from '../../images/proyectos/NATURA_REAL/NATURA_REAL_1.png'
import img3 from '../../images/proyectos/NATURA_REAL/NATURA_REAL_2.png'
import img4 from '../../images/proyectos/NATURA_REAL/BOX 8 Apartamentos_55m2.png'
import img5 from '../../images/proyectos/NATURA_REAL/BOX 9 habitación_tipo_moderno_natura_real.png'
import img6 from '../../images/proyectos/NATURA_REAL/BOX 10 Mesa de trabajo.png'
import img7 from '../../images/proyectos/NATURA_REAL/NATURA_REAL_4.png'
import img8 from '../../images/proyectos/NATURA_REAL/NATURA_REAL_3.png'
import img9 from '../../images/proyectos/NATURA_REAL/BOX 3 ceja_real.png'
import img10 from '../../images/proyectos/NATURA_REAL/BOX 4 características_ceja_real.png'
import img11 from '../../images/proyectos/NATURA_REAL/BOX 6 piscina_natura_real.png'
import img12 from '../../images/proyectos/NATURA_REAL/NATURA REAL APARTAMENTOS LA CEJA CAMPESTRES ZONAS COMUNES COMPLETAS UN PROYECTO EN CEJA REAL.png 9.png'

export default function NaturaReal() {
    const data =
        [
            {
                "name": "Natura Real",
                "slug": "natura-real",
                "subtitle": "APARTAMENTOS DE 55 m2 y 75 m2 - Etapa 1 y Etapa 2.",
                "zonas": "Este proyecto, te brindará la oportunidad de tener una vida urbana con tu familia o amigos en medio de la naturaleza y disfrutar de las mejores y completas zonas comunes como:",
                "location": "La Ceja, Antioquia, Colombia.",
                "text": "NATURA REAL te ofrece la oportunidad de conectar con la naturaleza dentro y fuera de tu hogar. Su diseño arquitectónico te permitirá tener un jardín interno, espacios personalizables y la oportunidad de elegir tus acabados según tu gusto los cuales se informará su costo previo a la entrega. En la segunda etapa, podrás vivir o invertir en apartamentos campestres de 2 o 3 habitaciones, baños, cocina abierta, sala, comedor y zona de ropas con ventilación natural; parqueadero cubierto y cuarto útil.",
                "text1": "El macroproyecto CEJA REAL, Natural y Bike Friendly con más de 3 km de ciclorruta integrada, un entorno planeado con Mall Comercial, Centro de Atención Médica, Casa del Adulto Mayor, Estación de Servicio y viviendas, se encuentra en ventas de su primer proyecto de apartamentos campestres llamado NATURA REAL compuesto por apartamentos de 55 m2 y 75 m2 con parqueadero cubierto y cuarto útil.",
                "text2": "Cuenta con una privilegiada ubicación en medio de la Nueva Circunvalar Sur doble Calzada con 4 vías de acceso: Vía Medellín, Vía Llanogrande, Vía Unión y Vía Abejorral. Adicionalmente, tendremos un acceso por la Casa de Retiros Espirituales “La Colombiere”. Encuéntranos en Waze o en Google Maps como Ceja Real, Macroproyecto y atrévete a conocer este paraíso del oriente antioqueño.",
                "video": "https://www.youtube.com/channel/UChnRgtI40lFiKVbQa4XN3jA",
                "whatapp": "https://api.whatsapp.com/send?phone=573115101010&text=Hola,%20me%20gustaria%20recibir%20mas%20informaci%C3%B3n%20y%20comunicarme%20con%20uno%20de%20sus%20asesores",
                "maps": "https://www.google.com/maps/dir/6.2012888,-75.5789893/ceja+real/@6.1214679,-75.5717503,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8e46975278687855:0x3f2cf8083a36eaab!2m2!1d-75.4269563!2d6.017723",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                    { image: img8, },
                    { image: img9, },
                    { image: img10, },
                    { image: img11, },
                    { image: img12, },
                ],
                "iconos": [
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Portería" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Salón de Monitoreo" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Placa Polideportiva" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Cancha en Grama Sintética Fútbol 5 " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Parques de Mascotas " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Senderos Ecológicos" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1. Cuarto de Basuras" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2. Salón Social" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2. Gimnasio" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2. Administración " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2. Plazoleta Social " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2. Juegos Infantiles" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Piscina con Carril de Nado Cubierta" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Piscina Recreativa" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Piscina de Niños" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Jacuzzi" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Circuito de Spa" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Salón de Masajes " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Sauna" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Turco" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Solárium" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Cancha de Vóley Playa" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Zona Lúdica" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Zona de picnic" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Cuarto de Químicos" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Local Minimercado" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-4. Local Coworking" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2, Et-3 y Et-5. Senderos Ecológicos" },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2, Et-3 y Et-5. Parqueaderos de Visitantes " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-2 y Et-4. Parqueaderos de Bicicletas " },
                    { icono: <i className="ti ti-bookmark" />, texto: "Et-1, Et-2, Et-3, Et-4 y Et-5. Vías Pavimentadas " },
                ]
            }
        ]
    return data;
}