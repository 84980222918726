import img1 from '../../images/trayectoria/VALPARAISO/Valparaíso_1.png'
import img2 from '../../images/trayectoria/VALPARAISO/Valparaíso_2.png'
import img3 from '../../images/trayectoria/VALPARAISO/Valparaíso_3.png'
import img4 from '../../images/trayectoria/VALPARAISO/Valparaíso_4.png'
import img5 from '../../images/trayectoria/VALPARAISO/Valparaíso_5.png'
import img6 from '../../images/trayectoria/VALPARAISO/Valparaíso_6.png'
import img7 from '../../images/trayectoria/VALPARAISO/Valparaíso_7.png'


export default function Valparaiso() {
    const data =
        [
            {
                "name": "Valparaíso",
                "slug": "valparaiso",
                "location": "Sabaneta, Antioquia.",
                "text": "Construimos en el SECTOR de Sabaneta, Antioquia; un Edificio Residencial de 242 apartamentos de 56 y 66 m2 en 34 pisos sobre un área de lote total de 3.558 m2; con zonas comunes: piscina para adultos con horizonte perdido, piscina de niños, turco, sauna, gimnasio, oficina de administración, salón social, cancha de microfútbol en grama sintética, cancha de vóley playa, juegos infantiles, parqueaderos y cuartos útiles.",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                ],
                "iconos": [
                    { icon: <i className="ti ti-user" />, title: 'PROYECTO', subtitle: 'Valparaíso.' },
                    { icon: <i className="ti ti-location-pin" />, title: 'SECTOR', subtitle: 'Sabaneta, Antioquia.' },
                    { icon: <i className="ti ti-home" />, title: 'TIPO', subtitle: 'Edificio Residencial.' },
                    { icon: <i className="ti ti-ruler-alt-2" />, title: 'CONSTRUYE', subtitle: 'Ingearco y cia s.a.s' },
                ]
            }
        ]
    return data;
}
