import React, { Fragment, Component } from 'react';

/*
import { Link } from 'react-router-dom';
import EventSlider from './Element/EventSlider';

import bgimg from '../../../../images/bg-view.png';


           
                <div className="container">
                    <div className="row align-items-end section-head">
                        <div className="col-md-6">
                            <h2 className="title">OTROS PROYECTOS</h2>
                            <div className="dlab-separator bg-primary"></div>
                            <p className="mb-3 mb-md-0">Tenemos diferentes sectores en desarrollo que te van a encantar…</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <Link to={"/proyectos"} className="btn btn-primary">Ver proyectos</Link>
                        </div>
                    </div>
          
                    <EventSlider />
         
                </div>
                <img src={bgimg} className="bg-view" alt="" />
  


*/

class EventSection extends Component {
    render() {
        return (
            <Fragment>

            </Fragment>
        )
    }
}
export default EventSection;