import img1 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_1.png'
import img2 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_2.png'
import img3 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_3.png'
import img4 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_4.png'
import img5 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_5.png'
import img6 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_6.png'
import img7 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_7.png'
import img8 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_8.png'
import img9 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_9.png'
import img10 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_10.png'
import img11 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_11.png'
import img12 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_12.png'
import img13 from '../../images/trayectoria/CIUDADELA_PREMIUM/SECTOR_CIUDAD_LA_PREMIUM_13.png'

export default function CiudadelaPremium() {
    const data =   
    [
        {
            "name": "Ciudadela Premium",
            "slug": "ciudadela-premium",
            "location": "San Jerónimo, Antioquia.",
            "text": "Construimos en el SECTOR de San Jerónimo, Antioquia; una Unidad Residencial tipo Resort de 231 casas con 11 piscinas, tobogán, 2 jacuzzis, sauna, turco, 2 kioscos, cancha de tenis de campo, 2 canchas de vóley playa, cancha de microfútbol en grama sintética, cancha de microfútbol en grama natural y juegos infantiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },  
                            {  image: img8,  },  
                            {  image: img9,  },  
                            {  image: img10,  },  
                            {  image: img11,  },  
                            {  image: img12,  },  
                            {  image: img13,  },  
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Ciudadela Premium' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'San Jerónimo, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Unidad Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
        }
    ]
    return data;
    }

