import React,{Component} from 'react';
import { Link } from "react-scroll";

class SideNav extends Component{
	render(){
		return(
			<>
				<ul className="navbar">
					<li>
						<Link
							activeClass="active"  to="sidenav_home" smooth={true} offset={-70} duration={500} className="scroll nav-link " >
							<i className="las la-home text-white"></i> <span className="text-white">inicio</span>
						</Link>
					</li>
					<li>
						<Link 
							activeClass="active" to="sidenav_aboutUs" className="scroll nav-link "  smooth={true} offset={-70} duration={500} >
							<i className="las la-user text-white"></i> <span className="text-white">SOBRE NOSOTROS</span>
						</Link>
					</li>
					<li>
						<Link 
							activeClass="active" to="sidenav_soponser" className="scroll nav-link"  smooth={true} offset={-70} duration={500}  >
							<i className="las la-chart-bar text-white"></i> <span className="text-white">NUESTROS ALIADOS</span>
						</Link>
					</li>

					<li>
						<Link 
							activeClass="active" to="footer" className="scroll nav-link"  smooth={true} offset={-70} duration={500} >
							<i className="las la-phone-volume text-white"></i> <span className="text-white">CONTÁCTANOS</span>
						</Link>
					</li>
				</ul>
			</>
		)
	}
}

export default SideNav;