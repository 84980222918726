import img1 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 2 citadela_di_sole.jpg'
import img2 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 3 citadela_di_sole_santa_fe_de_antioquia.jpg'
import img3 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 4 citadela_di_sole_ubicacion.jpg'
import img4 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 5 citadela_di_sole_primera_etapa.jpg'
import img5 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 7 apartasol_premium_spa.png'
import img6 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 8 apartasol_suite.png'
import img7 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 9 apartasol_deluxe.png'
import img8 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 10 apartasol_deluxe_3_reformas.png'
import img9 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 11 urbanismo_etapa_2_citadela_di_sole.png'
import img10 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 12 etapa_3_citadela_di_sole.png'
import img11 from '../../images/proyectos/CITADELA_DI_SOLE/BOX 13 di_sole.jpg'

export default function CitadelaDiSole() {
    const data =
        [
            {
                "name": "Citadela Di Sole",
                "slug": "citadela-di-sole",
                "subtitle": "APARTASOLES SUITE, DELUXE, PREMIUM - SPA de 56, 76 hasta 165 m2.",
                "zonas": "El proyecto te brindará la oportunidad de disfrutar de unas divertidas y descansadas vacaciones todo el año, con tu familia o amigos con amplias zonas comunes tipo resort como:",

                "location": "Santa Fe de Antioquia, Antioquia, Colombia.",
                "text": "CITADELA DI SOLE te ofrece un espacio para disfrutar de vacaciones ¡Todo el año! El proyecto cuenta con una incomparable zona recreativa tipo resort pensada en cada miembro de la familia, incluyendo: gran parque acuático, múltiples canchas deportivas, cine al aire libre, parques y zonas de descanso. Podrás elegir entre vivir o invertir en apartasoles Tipo A de 56 m2 Apartasoles Tipo B de 76 m2 El proyecto estará enmarcado en 38.400 m2 aproximadamente y podrás disfrutar de las completas y espectaculares zonas comunes:",
                "text1": "Ubicado a sólo 5 minutos del parque principal de Santa Fe de Antioquia y 2 minutos del Parque de la Chinca, en uno de los destinos turísticos más importantes de la región, Citadela di Sole es un completo proyecto de apartasoles para el descanso y el disfrute de la familia o amigos.",
                "text2": "La tradición, las calles coloniales y el clima cálido todo el año, están a solo 40 minutos de Medellín, y juntos crean el escenario perfecto para que escapar de la cotidianidad sea posible todo el año.",
                "video": "https://youtu.be/dAZjvEev-6U",
                "whatapp": "https://api.whatsapp.com/send?phone=573115101010&text=Hola,%20me%20gustaria%20recibir%20mas%20informaci%C3%B3n%20y%20comunicarme%20con%20uno%20de%20sus%20asesores",
                "maps": "https://goo.gl/maps/JXo2GjN5axpuiLDQA",
                "images": [
                    { image: img1, },
                    { image: img2, },
                    { image: img3, },
                    { image: img4, },
                    { image: img5, },
                    { image: img6, },
                    { image: img7, },
                    { image: img8, },
                    { image: img9, },
                    { image: img10, },
                    { image: img11, },
                ],
                "iconos": [
                    { icono: <i className="ti ti-bookmark" />, texto: "Piscina Adultos." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Piscina de Niños." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parque Acuático Infantil." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha de Vóley Playa." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha Polideportiva." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha en Grama Natural." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Cancha Fútbol 5 en Grama Sintética." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Gimnasio Dotación Básica." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Salón Social." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Teatro al Aire Libre." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parque de Mascotas." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parque Infantil." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Parque de Hamacas." },
                    { icono: <i className="ti ti-bookmark" />, texto: "Portería." },
                ]
            }
        ]
    return data;
}