import React, { Fragment, Component } from 'react';
import WOW from 'wowjs';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';
import Header from '../../Layout/Header';
import Footer2 from '../../Layout/Footer2';
import PageTitle from '../../Layout/PageTitle';
import { ProDetail, ButtonPara, BlogPage } from './components/Data';
import { GallerySection } from './components/GallerySection';
import ProyectosService from '../../../infrastructure/services/ProyectosService';
import { Link } from 'react-router-dom';
import GaleriaPortfolioNaturaReal from '../../Element/GaleriaPortfolioNaturaReal';
import MapBox from '../../Element/MapBox';

class NaturaReal extends Component {
    componentDidMount() {
        new WOW.WOW().init();
    }
    render() {
        const title = window.location.pathname.replace('/proyecto/', '');
        let data = ProyectosService(title);
        return (
            <Fragment>
                <Header />
                {data.map((data, index) => (
                    <div className="page-content bg-white" id="lightgallery">
                        <PageTitle motherMenu={data.name} activeMenu={data.slug} />
                        <SimpleReactLightbox>
                            <SRLWrapper >
                                <div className="section-full content-inner-2 fondoOndasUP ">
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[0].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[8].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[9].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6 m-b30">
                                                <p className="m-b0">{data.text1}</p>
                                            </div>
                                            <div className="col-lg-6 m-b30">
                                                <p className="m-b0">{data.text2}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Link to={"/proyecto/ceja-real"} className="btn btn-primary m-r20">CEJA REAL<span></span></Link>
                                        <Link to={{ pathname: data.maps }} className="btn btn-primary" target="_blank">VER UBICACIÓN<span></span></Link>
                                    </div>
                                </div>
                                <div className="section-full content-inner-2">
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[10].image} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-full content-inner-2 ">
                                    <div className="container">
                                        <div className="row">
                                            <div className="container">
                                                <div className="row section-head">
                                                    <div className="col-lg-12" >
                                                        <p>{data.location}</p>
                                                        <h4 className="title">{data.name}</h4>
                                                        <p>{data.subtitle}</p>
                                                    </div>
                                                    <div className="col-lg-12 align-self-center">
                                                        <p className="m-b0">{data.text}</p>
                                                    </div>
                                                </div>
                                                <div className="row section-head">
                                                    <div className="col-lg-12" >
                                                        <h4 className="subtitle">ZONAS COMUNES</h4>
                                                    </div>
                                                    <div className="col-lg-12 align-self-center">
                                                        <p>{data.zonas}</p>
                                                    </div>
                                                </div>
                                                <div className="row m-b30 widget widget_getintuch widget_getintuch-pro-details pro-details-col-5 m-lr0">
                                                    {data.iconos.map((data, index) => (
                                                        <div className="col-lg-4" key={index}>
                                                            <div className="pro-details">{data.icono}{data.texto}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-full content-inner-2 fondoOndasDown">
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[3].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[11].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[4].image} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="col-lg-12 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                            <div className="dlab-box gallery-box-2 m-b30">
                                                <BlogPage imageType={data.images[5].image} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                ))}
                <GaleriaPortfolioNaturaReal />
                <MapBox />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '800px' }}>
                    <iframe
                        src="https://app.cronos.com.co/comercial/formconstructor?id=b9a8aadb-4601-4dd9-8a28-45e9868b9d2f"
                        width="700"
                        height="800"
                        title="Natura Real"
                        style={{ border: '0' }} // Opcional: elimina el borde del iframe
                    ></iframe>
                </div>
                <Footer2 />
            </Fragment>
        )
    }
}


export default NaturaReal;
