import img1 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_1.png'
import img2 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_2.png'
import img3 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_3.png'
import img4 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_4.png'
import img5 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_5.png'
import img6 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_6.png'
import img7 from '../../images/trayectoria/TERRAGRANDE/SECTOR_TERRAGRANDE_7.png'

export default function Terragrande() {
    const data =   
    [
        {
            "name": "Terragrande",
            "slug": "terragrande",
            "location": "Rionegro, Antioquia.",
            "text": "Construimos en el SECTOR Llanogrande, Antioquia; un Conjunto de Casas de doble altura en medio de un ambiente natural y tranquilo; acompañado de zonas comunes como: gimnasio, salón Social, zona de Lectura, parque para fogata, juegos infantiles, turco, sauna, cancha en grama sintética y juegos infantiles.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },  
                    ],
                    "iconos":[
                        {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Terragrande' },	
                        {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Llanogrande, Antioquia.' },	
                        {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Conjunto de Casas.' },	
                        {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                      ]
    }
]
return data;
}
