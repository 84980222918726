import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import CountUp from 'react-countup';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';


//Images 
import bnr1 from './../../../images/nuestro-equipo/PROMOCIONAMOS_SECTOR.png';
import bnr2 from './../../../images/nuestro-equipo/GERENCIAMOS_SECTOR.png';
import bnr3 from './../../../images/nuestro-equipo/CONSTRUIMOS_SECTOR.png';
import bnr4 from './../../../images/nuestro-equipo/VENDEMOS_SECTOR.png';

// 
import logo1 from './../../../images/logo/ALIANZA-FIDUCIARIA.png';
import logo2 from './../../../images/logo/INGEARCO.png';
import logo3 from './../../../images/logo/SECTOR-CONSTRUCTOR.png';
import logo4 from './../../../images/logo/SECTOR-INMOBILIARIO.png';
import logo5 from './../../../images/logo/SECTOR-COMERCIAL.png';

import thum1 from './../../../images/about/REFIERE Y GANA EN SECTOR.png';

const backgrounBlog = [
    { image: bnr1, title: 'Promocionamos', text: 'Somos expertos en visionar sectores que posean un gran potencial, convirtiéndolo en un gran desarrollo con alta valorización aportando al desarrollo de nuestro país.' },
    { image: bnr2, title: 'Gerenciamos', text: 'Optimizamos el uso de los recursos garantizando siempre la calidad y el capital humano, aportando significativamente a la economía y a la sociedad.' },
    { image: bnr3, title: 'Construimos', text: 'Nos encargamos del proceso de la construcción cumpliendo con la normatividad de manera eficiente, eficaz y en el menor tiempo posible.' },
    { image: bnr4, title: 'Vendemos', text: 'Nos aseguramos que conozcas nuestros proyectos, explicarlos a profundidad y dejamos en nuestros clientes la satisfacción de invertir en sectores de gran rentabilidad.' },
];

const counterBlog = [
    { icon: <i className="ti-home" />, num: '3695', title: 'Viviendas Construidas', },
    { icon: <i className="ti-user" />, num: '30000', title: 'Empleos Generados', },
    { icon: <i className="ti-medall-alt" />, num: '19', title: 'Sectores Desarrollados', },
    { icon: <i className="ti-face-smile" />, num: '11000', title: 'clientes Felices', },
];
const ServiceBlog = ({ serviceImage, serviceTitle }) => {
    return (
        <div className="icon-bx-wraper sr-iconbox">
            <div className="icon-lg m-b20">
                <Link to={"#"} className="icon-cell"><img src={serviceImage} alt="" /></Link>
            </div>
            <div className="icon-content">
                <h2 className="dlab-tilte">{serviceTitle}</h2>
                <p>I have worked with many companies offering supplemental industrial services, and out of all those companies </p>
            </div>
        </div>
    )
}
class Aboutus extends Component {
    componentDidMount() {
        new WOW.WOW().init();
    }
    render() {
        return (
            <Fragment>
                <Header />
                <div className="page-content bg-white">
                    <PageTitle motherMenu="Nuestro Equipo" activeMenu="nuestro-equipo" />					{/*  Counters  */}
                    <CounterSection />
                    {/*  Counters  End */}
                    {/* Section-1 End */}
                    <section className="content-inner-1" data-content="SECTOR">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="title">+28 Años construyendo historias en Colombia</h2>
                                <p>Somos un grupo de personas apasionadas por lo que hacemos. Promocionar, gerenciar, vender y construir soluciones inmobiliarias en sectores colombianos, es parte de nuestras vidas. Nuestra mayor satisfacción está en ver la transformación de un SECTOR al convertirlo en un <br /> GRAN DESARROLLO para el futuro.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-lg-3 col-6 mb-4 mt-lg-3 mt-0">
                                    <div className="aminite-icon-bx"><img src={logo2} alt="Ingearco y CIA. S.A.S" /><h4 className="title">Ingearco y CIA. S.A.S</h4></div>
                                </div>
                                <div className="col-md-4 col-lg-3 col-6 mb-4 mt-lg-5 mt-0">
                                    <div className="aminite-icon-bx"><img src={logo3} alt="Sector Constructor" /><h4 className="title">Sector Constructor</h4></div>

                                </div>
                                <div className="col-md-4 col-lg-3 col-6 mb-4 mt-lg-5 mt-0">
                                    <div className="aminite-icon-bx"><img src={logo4} alt="Sector Inmobiliario" /><h4 className="title">Sector Inmobiliario</h4></div>

                                </div>
                                <div className="col-md-4 col-lg-3 col-6 mb-4 mt-lg-3 mt-0">
                                    <div className="aminite-icon-bx"><img src={logo5} alt="Sector Comercial" /><h4 className="title">Sector Comercial</h4></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="content-inner-1" data-content="Sector" id="masterPlan">
                        <div class="container">
                            <div class="section-head text-center">
                                <h2 class="title">NOS ENCARGAMOS DE TODO EL PROCESO</h2>
                                <div class="dlab-separator bg-primary"></div>
                                <p>Somos un gran equipo y usamos todo nuestro talento humano para brindarte acompañamiento en todo el proceso de tu nueva vivienda y/o inversión.</p>
                            </div>
                        </div>
                    </section>

                    <section>
                        {backgrounBlog.map((data, index) => (
                            <div className="aminite-overlay parallax" style={{ backgroundImage: "url(" + data.image + ")" }} key={index}>
                                <div className="aminite-over-area">
                                    <div className="overlay-box">
                                        <h2 className="title">{data.title}</h2>
                                        <p>{data.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </section>
                    <section className="content-inner-1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-12 m-b50">
                                    <div className="dlab-card blog-half">
                                        <MediaBlog thumImage={thum1} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* 
                    


                    *****************************
                    
                    Services Section 
					<section className="content-inner-2" data-content="SECTOR">
							<div className="container">
								<div className="row">
									<div className="col-lg-3 col-md-3 col-sm-6 col-12 m-md-b30 wow fadeIn" data-wow-delay="0.2s"  data-wow-duration="2s">
										<ServiceBlog serviceImage={colrimg1} serviceTitle="Promoción"  />
									</div>
									<div className="col-lg-3 col-md-3 col-sm-6 col-12 m-md-b30 wow fadeIn" data-wow-delay="0.4s"  data-wow-duration="2s">
										<ServiceBlog serviceImage={colrimg2} serviceTitle="Gerencia"  />
									</div>
									<div className="col-lg-3 col-md-3 col-sm-12 col-12 wow fadeIn" data-wow-delay="0.6s"  data-wow-duration="2s">
										<ServiceBlog serviceImage={colrimg3} serviceTitle="Venta"  />
									</div>
									<div className="col-lg-3 col-md-3 col-sm-12 col-12 wow fadeIn" data-wow-delay="0.6s"  data-wow-duration="2s">
										<ServiceBlog serviceImage={colrimg2} serviceTitle="Construcción"  />
									</div>
								</div>
							</div>
						</section>
						*/  }
                </div>
                <Footer2 />
            </Fragment>
        )
    }
}

function CounterSection() {
    return (
        <>
            <div className="bg-light content-inner" data-content="OUR TEAM">
                <div className="container">
                    <div className="row">
                        {counterBlog.map((data, index) => (
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6 m-b30" key={index}>
                                <div className="counter-left archia-counter">
                                    <div className="icon-lg pull-left m-tb10">{data.icon}</div>
                                    <div className="clearfix m-l90">
                                        <div className="counter m-b5"><CountUp end={data.num} duration={4} /> </div>
                                        <span className="font-16 text-black">{data.title}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
const MediaBlog = ({ thumImage }) => {
    return (
        <>
            <div className="dlab-media wow fadeIn" data-wow-delay="0.2s" data-wow-duration="2s">
                <img src={thumImage} alt="" />
            </div>
            <div className="dlab-info">

                <h3 className="dlab-title">
                    <Link to={"/blog-details"}>¡REFIERE Y GANA! </Link>
                </h3>
                <p>Conoce nuestro Plan de Referidos, términos y condiciones haciendo clic en el siguiente botón. </p>
                <div className="dlab-readmore">
                    <a href="https://sector-bucket.s3.us-east-2.amazonaws.com/PLAN_REFERIDOS_2024.pdf" target='_blank' className="readmore"><i className="las la-plus"></i>DESCARGAR PLAN DE REFERIDOS </a>
                </div>
            </div>
        </>
    )
}
export { CounterSection }
export default Aboutus;