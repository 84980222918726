import React from 'react'
import { Link } from 'react-router-dom';
import bgimg from './../../images/bg-view.png';

export default function MapBox() {
    return (
        <div className="container">
            <section className="content-inner-2">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="title">¡CONÓCENOS MÁS!</h2>
                    </div>
                    <div className="banner-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.8381324450825!2d-75.426479!3d6.0169492999999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e46975278687855%3A0x3f2cf8083a36eaab!2sCeja%20Real%2C%20Macroproyecto!5e0!3m2!1sen!2sca!4v1719184399233!5m2!1sen!2sca"
                            className="border-0 align-self-stretch rounded-sm" style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen
                        />
                    </div>
                </div>
            </section>
            <section className="content-inner-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="icon-bx-wraper m-sm-b30 left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-phone-volume"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">TELÉFONOS</h3>
                                    <p>Sala de Negocios: (+57) 3115101010<br />
                                        Asesora Catalina: (+57) 3113403242<br />
                                        Asesora Katherine: (+57) 3205205252 <br />
                                        Asesora Susana: (+57) 3205202020<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="icon-bx-wraper m-sm-b30 left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-map"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">DIRECCIÓN</h3>
                                    <p>Calle 6 con Avenida Circunvalar Sur<br />
                                        Sector Horizontes<br />
                                        La Ceja<br />
                                        Antioquia, Colombia.<br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="icon-bx-wraper left">
                                <div className="icon-md m-b20 m-t5">
                                    <Link to={"#"} className="icon-cell text-primary">
                                        <i className="las la-envelope-open"></i>
                                    </Link>
                                </div>
                                <div className="icon-content">
                                    <h3 className="dlab-tilte m-b5">CORREO</h3>
                                    <p>Sala de Negocios: cejareal@sector.com.co<br />
                                        Trámites: tramites@sector.com.co<br />
                                        Coordinación Comercial: coor.comercial@sector.com.co <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
