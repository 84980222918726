import img1 from '../../images/trayectoria/SAN_LORENZO_DEL_ESCORIAL/Sector_San_Lorenzo_del_Escorial_1.png'
import img2 from '../../images/trayectoria/SAN_LORENZO_DEL_ESCORIAL/Sector_San_Lorenzo_del_Escorial_2.png'


export default function SanLorenzodelEscorial() {
    const data =   
    [
        {
            "name": "San Lorenzo del Escorial",
            "slug": "san-lorenzo-del-escorial",
            "location": "Medellín, Antioquia.",
            "text": "Construimos en el SECTOR de Medellín, Antioquia; una Torre Residencial de 213 apartamentos con portería, terraza y parqueaderos en una ubicación privilegiada cerca de colegios, comercio y al centro de la ciudad.",
            "images":[
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img1,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'San Lorenzo del Escorial.' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Medellín, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Edificio Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }
