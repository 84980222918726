import React, { Fragment, Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Masonry from 'react-masonry-component';

//images
import box1 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-1.png';
import box2 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-2.png';
import box3 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-3.png';
import box4 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-4.png';
import box5 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-5.png';
import box6 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-6.png';
import box7 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-7.png';
import box8 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-8.png';
import box9 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-9.png';
import box10 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-10.png';
import box11 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-11.png';
import box12 from './../../images/proyectos/CITADELA_DI_SOLE/AVANCE DE OBRA/avance-de-obra-citadela-di-sole-12.png';




const imageBlog = [
    { Large_img: box1, tag: ['Designing', 'Technology',], },
    { Large_img: box2, tag: ['Abstract', 'Mockup', 'Technology'], },
    { Large_img: box3, tag: ['Technology', 'Mockup'], },
    { Large_img: box4, tag: ['Food', 'Abstract', 'Technology'], },
    { Large_img: box5, tag: ['Food', 'Designing', 'Technology'], },
    { Large_img: box6, tag: ['Food', 'Abstract'], },
    { Large_img: box7, tag: ['Technology', 'Food'], },
    { Large_img: box8, tag: ['Abstract', 'Mockup', 'Technology'], },
    { Large_img: box9, tag: ['Abstract', 'Mockup', 'Technology'], },
    { Large_img: box10, tag: ['Abstract', 'Mockup', 'Technology'], },
    { Large_img: box11, tag: ['Abstract', 'Mockup', 'Technology'], },
    { Large_img: box12, tag: ['Abstract', 'Mockup', 'Technology'], },
]

// Masonry section
const masonryOptions = {
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end





const TagLi = ({ name, handlesettag, tagActive }) => {
    return (

        <li className={` tag ${tagActive ? 'btn active' : 'btn'}`} onClick={() => handlesettag(name)} >
            <input type="radio" />
            <Link to={"#"} className="site-button-secondry button-skew" >	<span>{name} {''}</span> </Link>
        </li>
    );
};

class GaleriaPortfolioCitadela extends Component {
    componentDidMount() {
        new WOW.WOW().init();

    }
    render() {
        const { imageBlogs } = this.props; // Obtener imageBlogs de las props

        return (
            <div className="page-content bg-white">
                <PortfolioItem imageBlogs={imageBlogs} />

            </div>
        )
    }
}

function PortfolioItem({ imageBlogs }) {
    const [tag, setTag] = useState('All');
    const [filteredImages, setFilterdImages] = useState([]);

    useEffect(() => {
        tag === 'All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter(image => image.tag.find(element => element === tag)))
    }, [tag])

    return (
        <>
            <section className="content-inner-2" data-content="AVANCE">
                <div className="container">
                    <div className="section-head text-center">
                        <h2 className="title m-b10">AVANCE DE OBRA</h2>
                    </div>
                </div>
                <div className="clearfix">
                    <ul className="gallery mfp-gallery text-center portfolio-bx p-l0 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                        <Masonry
                            className={'my-gallery-class'} // default ''
                            options={masonryOptions} // default {}
                            disableImagesLoaded={false} // default false
                            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                            imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                            {filteredImages.map((item, index) => (

                                <li data-category="abstract" className="card-container col-lg-3 col-md-4 col-sm-6 p-lr0 abstract" key={index}>
                                    <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox1">
                                        <img src={item.Large_img} alt="" />

                                    </div>
                                </li>
                            ))}
                        </Masonry>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default GaleriaPortfolioCitadela;