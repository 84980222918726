import img1 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_1.png'
import img2 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_2.png'
import img3 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_3.png'
import img4 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_4.png'
import img5 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_5.png'
import img6 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_6.png'
import img7 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_7.png'
import img8 from '../../images/trayectoria/ESSENZA/Sector_Essenza_Lotes_Rionegro_8.png'


export default function Essenza() {
    const data =   
    [
        {
            "name": "Essenza",
            "slug": "essenza",
            "location": "Rionegro, Antioquia.",
            "text": "Construimos en el SECTOR de Rionegro, Antioquia; una exclusiva Parcelación Residencial de 136 lotes con áreas desde 1.900 m2 hasta 10.000 m2 en un lote total de 66 hectáreas con árboles nativos, majestuosas montañas, espectacular lago, 2 porterías con acceso variante Las Palmas y El Tablazo, sendero lineal de 4 km, salón de reuniones, iluminación de zonas comunes con paneles solares en luz led, servicios de agua, luz, alcantarillado y tuberías subterráneas para internet, tv y teléfono.",
            "images": [
                            {  image: img1,  },
                            {  image: img2,  },
                            {  image: img3,  },  
                            {  image: img4,  },  
                            {  image: img5,  },  
                            {  image: img6,  },  
                            {  image: img7,  },  
                            {  image: img8,  }, 
                        ],
                        "iconos":[
                            {icon: <i className="ti ti-user" />, 			title:'PROYECTO',    subtitle: 'Essenza' },	
                            {icon: <i className="ti ti-location-pin" />, 			title:'SECTOR', subtitle: 'Rionegro, Antioquia.' },	
                            {icon: <i className="ti ti-home" />, 	title:'TIPO',  subtitle: 'Parcelación Residencial.' },	
                            {icon: <i className="ti ti-ruler-alt-2" />, 	title:'CONSTRUYE',      subtitle: 'Ingearco y cia s.a.s' },	
                          ]
            }
            ]
            return data;
            }

